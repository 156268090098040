import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import * as action from "store/actions";
import LightBox from 'components/LightBox';
import { GlobalContext } from 'store/context/GlobalContext';
import { getCart, deleteCartItem, updateCartItem, orderSubmit } from 'services/authService';
import PullToRefresh from 'react-simple-pull-to-refresh';
import CircularProgress from '@mui/material/CircularProgress';
import useKeyboard from 'components/useKeyboard';
import ConfirmButton from 'components/ConfirmButton';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

export default function Cart() {
    let mounted = true;
    const dispatch = useDispatch();
    const context = useContext(GlobalContext);
    const { setSnack, setLoader, setFreshCart } = context;
    const [ loading, setLoading ] = useState(false);
    const [ lightboxToggle, setLightboxToggle ] = useState(false);
    const [ note, setNote ] = useState("");
    const [ shippingMethod, setShippingMethod ] = useState("delivery");
    const [ orderNo, setOrderNo ] = useState();
    const cartData = useSelector((state) => state.Cart);

    useEffect(() => {
        return () => mounted = false;
    }, [])

    const fetchCart = useCallback(async() => {
        let result = await getCart();
        if(mounted){
            dispatch(action.updateCart(result.data));
        }
    }, [mounted])

    const onRefresh = async() => {
        return await Promise.all([
            fetchCart(),
        ]);
    }

    const onSubmit = () => {
        setLoader(true);
        setLoading(true);
        orderSubmit({note: note, shipping_method: shippingMethod}).then(res => {
            setFreshCart(true);
            setLoader(false);
            setLoading(false);
            setLightboxToggle(true);
            setOrderNo(res.data.order_number);
        }).catch(err => {
            setLoader(false);
            setLoading(false);
            setSnack({open: true, text: err.messages.error});
            setFreshCart(true);
        })
    }

    return (<>
        <div className="cart-page">
            <PullToRefresh
                className="pull-to-refresh"
                pullDownThreshold={75}
                maxPullDownDistance={120}
                resistance={2}
                onRefresh={() => onRefresh()}
                pullingContent=""
                refreshingContent={<div className="pdy-3 pdt-6"><CircularProgress color="inherit" size={30} /></div>}
            >
                <div className="cart-container">
                    {
                        cartData.items.length > 0 ? (<>
                            <div className="font-size-20 fw-bold text-main pdt-6 mgb-3">Your Shopping Cart</div>
                            <ul className="cart-list">
                                { cartData.items.map((item, index) => <CartItem item={item} index={index} key={index} />) }
                            </ul>
                            <div className="pd-3 fw-bold border-bottom" style={{margin: "0 -15px"}}>
                                <div className="d-flex flex-wrap">
                                    <span className="fw-bold" style={{marginTop: "3px"}}>Shipping Method：</span>
                                    <RadioGroup defaultValue="delivery">
                                        <FormControlLabel
                                            sx={{margin: "0"}}
                                            value="delivery"
                                            control={<Radio color="primary" size="small" />}
                                            label="Delivery"
                                            labelPlacement="end"
                                            onChange={() => setShippingMethod("delivery")}
                                        />
                                        <FormControlLabel
                                            sx={{margin: "0"}}
                                            value="pickup"
                                            control={<Radio color="primary" size="small" />}
                                            label="Pickup"
                                            labelPlacement="end"
                                            onChange={() => setShippingMethod("pickup")}
                                        />
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className="pdy-3 d-flex flex-wrap fw-bold font-size-14">
                                <div className="col-12 col-sm-6 mgy-1">Est Dimension (Cu in)：<span className="text-main">{cartData.totalDimension}</span></div>
                                <div className="col-12 col-sm-6 mgy-1">Est Weight (lbs)：<span className="text-main">{cartData.totalWeight}</span></div>
                                <div className="col-12 col-sm-6 mgy-1">Subtotal：<span className="text-main">${cartData.subTotal}</span></div>
                                { shippingMethod === "delivery" && <div className="col-12 col-sm-6 mgy-1">Shipping Cost：<span className="text-main">${cartData.deliveryFee}</span></div> }
                            </div>
                            <div className="pd-3 fw-bold font-size-16 border-top" style={{margin: "0 -15px"}}>
                                <div className="col-sm-6">Grand Total：<span className="text-main">${shippingMethod === "delivery" ? cartData.total : Math.round((cartData.total - cartData.deliveryFee)*10000)/10000}</span></div>
                                <div className="mgt-6 d-flex">
                                    <span className="font-size-16 fw-bold">Note：</span>
                                    <textarea rows="3" onChange={(e) => setNote(e.target.value)} spellCheck={false} value={note} />
                                </div>
                            </div>
                            <div className="submit-container">
                                <ConfirmButton
                                    className="submit-btn d-inline-flex justify-content-center align-items-center"
                                    onSuccess={onSubmit}
                                    confirmText="Confirm to place order?"
                                    confirm={{show: true, text: 'Yes'}}
                                    cancel={{show: true, text: 'No'}}
                                    style={{maxWidth: "400px"}}
                                    disabled={loading || cartData.items.filter(e => !e.status || e.stock === 0).length > 0}
                                >
                                    Place Order
                                </ConfirmButton>
                            </div>
                        </>) : (<>
                            <div className="text-center" style={{margin: "100px 0"}}>
                                <i className="fal fa-shopping-bag fa-5x"></i>
                                <div className="font-size-18 text-main mgt-3">Your Cart is empty</div>
                                <div className="text-secondary mgt-3">Looks like you haven't added anything to your cart yet.</div>
                                <Link className=" pdy-2 pdx-4 mgt-6 text-white d-inline-block bg-main" to="/inventory">Shop Now</Link>
                            </div>
                        </>)
                    }
                </div>
            </PullToRefresh>
        </div>
        <LightBox
            open={lightboxToggle}
            callback={() => setLightboxToggle(false)}
        >
            <div className="light-box-body bg-white pdy-6 text-center">
                <div className="text-main font-size-18 fw-bold">Thank You</div>
                <div className="mgt-3 mgb-6">Order #{orderNo} submitted!</div>
                <button type="button" className="submit-btn" style={{maxWidth: "100px", height: "40px"}} onClick={() => setLightboxToggle(false)}>Close</button>
            </div>
        </LightBox>
    </>)
}


const CartItem = (props) => {
    const dispatch = useDispatch();
    const { item, index } = props;
    const cartData = useSelector((state) => state.Cart);
    const context = useContext(GlobalContext);
    const { setSnack, setLoader, freshCart, setFreshCart } = context;
    const [ loading, setLoading ] = useState(false);
    const [ quantity, setQuantity ] = useState("");
    const [ maxQuantity, setMaxQuantity ] = useState({maximum: null, limit: 0, stock: 0});
    const [ currentQuantity, setCurrentQuantity ] = useState("");
    const { handleKeyDown } = useKeyboard();

    const handleMaximum = useCallback(() => {
        // 情況一 前台有人手動退單
        // stock 30
        // safety_limit 20
        // limit 2
        // order_limit_quantity 1
        // (maximum: 1, stock: 0, limit: 1)

        // 情況二 後台reset過限購或沒買過限購且庫存高於安全值
        // stock 30
        // safety_limit 20
        // limit 2
        // order_limit_quantity 0
        // (maximum: 12, stock: 10, limit: 2)

        // 情況三 後台reset過限購或沒買過限購且庫存低於安全值
        // stock 10
        // safety_limit 20
        // limit 2
        // order_limit_quantity 0
        // (maximum: 2, stock: 0, limit: 2)

        // 情況四 買滿限購
        // stock 10
        // safety_limit 20
        // limit 2
        // order_limit_quantity 2
        // (maximum: 0, stock: 0, limit: 0)

        //原始 o_stock 20
        //原始 o_safety_limit 0
        //原始 o_limit 0
        // (maximum: 20, stock: 20, limit: 0, cart: 0) o_stock - cart = stock
        // (maximum: 10, stock: 10, limit: 0, cart: 10) o_stock - cart = stock
        // (maximum: 9, stock: 9, limit: 0, cart: 11) o_stock - cart = stock
        // (maximum: 8, stock: 8, limit: 0, cart: 12) o_stock - cart = stock
        // (maximum: 0, stock: 0, limit: 0, cart: 20) o_stock - cart = stock

        //原始 o_stock 20
        //原始 o_safety_limit 10
        //原始 o_limit 2
        //原始 o_order_limit_quantity 0
        // (maximum: 12, stock: 10, limit: 2, cart: 0) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 7, stock: 5, limit: 2, cart: 5) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 2, stock: 0, limit: 2, cart: 10) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 1, stock: 0, limit: 1, cart: 11) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 0, stock: 0, limit: 0, cart: 12) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0

        //原始 o_stock 10
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_order_limit_quantity 0
        // (maximum: 5, stock: 0, limit: 5, cart: 0) stock = 0
        // (maximum: 4, stock: 0, limit: 4, cart: 1) stock = 0
        // (maximum: 3, stock: 0, limit: 3, cart: 2) stock = 0
        // (maximum: 0, stock: 0, limit: 0, cart: 5) stock = 0

        //原始 o_stock 10
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_order_limit_quantity 1
        // (maximum: 4, stock: 0, limit: 4, cart: 0) stock = 0
        // (maximum: 3, stock: 0, limit: 3, cart: 1) stock = 0
        // (maximum: 2, stock: 0, limit: 2, cart: 2) stock = 0
        // (maximum: 1, stock: 0, limit: 1, cart: 3) stock = 0
        // (maximum: 0, stock: 0, limit: 0, cart: 4) stock = 0

        //原始 o_stock 10
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_order_limit_quantity 5
        // (maximum: 0, stock: 0, limit: 0, cart: 0) stock = 0

        let maximum = item.stock; //沒限購情況 maximum = 庫存
        let stock = item.stock;
        if(item.limit > 0 && item.safety_limit > 0){
            //有限購情況下
            if(item.order_limit_quantity < item.limit){
                if(item.order_limit_quantity > 0) {
                    //有買過限購但沒有超出限購量
                    maximum = item.limit-item.order_limit_quantity;
                    stock = 0;
                }else if(item.order_limit_quantity === 0) {
                    //沒有買過任何限購商品
                    if(item.stock > item.safety_limit){
                        //且庫存 > 安全值
                        maximum = item.stock-item.safety_limit+item.limit-item.order_limit_quantity;
                        stock = item.stock-item.safety_limit > 0 ? item.stock-item.safety_limit : 0;
                    }else{
                        if(item.stock <= item.limit){
                            maximum = item.stock;
                        }else{
                            maximum = item.limit;
                        }
                        stock = 0;
                    }
                }
            }else{
                //買滿限購量
                maximum = 0;
                stock = 0;
                setQuantity("0");
            }
        }

        setMaxQuantity({...maxQuantity, maximum: maximum, stock: stock, limit: maximum - stock});
    }, [item])

    useEffect(() => {
        handleMaximum();
    }, [handleMaximum])

    useEffect(() => {
        if(maxQuantity.maximum && item.quantity > maxQuantity.maximum ){
            setQuantity(maxQuantity.maximum);
            setCurrentQuantity(maxQuantity.maximum);
            handleUpdateCartItem(maxQuantity.maximum);
            return;
        }
        setQuantity(item.quantity);
        setCurrentQuantity(item.quantity);
    }, [item, maxQuantity.maximum])

    const handleUpdateCartItem = (value) => {
        if(!item.status) return;
        setLoading(true);
        updateCartItem(item.uuid, {quantity: value}).then(res => {
            setFreshCart(true);
        }).catch(err => {
            setLoading(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const handleChange = (value) => {
        if(!item.status) return;
        if(parseInt(value) > maxQuantity.maximum){
            setQuantity(maxQuantity.maximum);
        }else{
            setQuantity(value);
        }
    }

    useEffect(() => {
        if(!freshCart) setLoading(false);
    }, [freshCart])

    const handleDelete = () => {
        let newCartData = {...cartData};
        newCartData.items.splice(index, 1);
        newCartData.subTotal = Math.round((newCartData.subTotal - item.price*item.quantity)*10000)/10000;
        newCartData.count -= 1;
        newCartData.total = Math.round((newCartData.total - item.price*item.quantity)*10000)/10000;
        dispatch(action.updateCart(newCartData));
        deleteCartItem(item.uuid);
    }
    
    return (
        <li className="cart-item">
            {
                (!item.status || item.stock === 0) &&
                <div className="remove-box">
                    <button type="button" onClick={() => handleDelete()}>Remove item</button>
                </div>
            }
            <div className="d-flex align-items-stretch flex-fill">
                {
                    (item.image !== "" && item.image) ? (
                        <div className="cart-image" style={{backgroundImage: `url(${item.image})`}}></div>
                    ) : (
                        <div className="cart-image"></div>
                    )
                }
                <div className="cart-info">
                    <div className="category">{item.category.name}</div>
                    <div className="name">{item.name}</div>
                    <div className="name">{item.zh_name}</div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="price">Price ($)：{item.price}</div>
                        <button className="remove-btn" onClick={() => handleDelete()}><i className="far fa-trash-alt mgr-1"></i>remove item</button>
                    </div>
                </div>
            </div>
            <div className="quantity-container d-flex align-items-center justify-content-end">
                {/* { item.limit && <div className="mgr-2 font-size-12 text-main">Maximum: {item.limit}</div> } */}
                <div className="quantity-box">
                    <button
                        type="button"
                        className="quantity-btn"
                        onClick={() => {
                            if(quantity > 1){
                                setQuantity(quantity - 1);
                                handleUpdateCartItem(quantity - 1);
                            } 
                        }}
                        disabled={parseInt(quantity) <= 1 || loading || !item.status}
                        >-
                    </button>
                    <input
                        tabIndex="1"
                        type="number"
                        className="quantity-input"
                        step="1"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        autoComplete="off"
                        spellCheck="false"
                        value={quantity}
                        onChange={(e) => handleChange(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, { number: true })}
                        onWheel={(e) => e.target.blur()}
                        onPaste={(e) => e.preventDefault()}
                        onBlur={(e) => {
                            if(e.target.value === "" || parseInt(e.target.value) < 1){
                                setQuantity(1);
                                handleUpdateCartItem(1);
                            }else{
                                setQuantity(parseFloat(e.target.value));
                                if(currentQuantity !== parseInt(e.target.value)){
                                    handleUpdateCartItem(quantity);
                                }
                            }
                        }}
                        readOnly={loading || !item.status}
                    />
                    <button
                        type="button"
                        className="quantity-btn"
                        onClick={() => {
                            setQuantity(quantity + 1);
                            handleUpdateCartItem(quantity + 1);
                        }}
                        disabled={parseInt(quantity) >= maxQuantity.maximum || loading || !item.status}
                        >+
                    </button>
                </div>
            </div>
            <div className="mgt-3 d-flex justify-content-between align-items-center">
                <div className="text-main fw-bold">Item Total：${quantity !== "" ? Math.round(item.price*parseInt(quantity)*10000)/10000 : '0'}</div>
                {
                    item.status ? (
                        <div className="text-main">
                            <div className="fw-bold">Maximum：{maxQuantity.maximum ? maxQuantity.maximum : "0"}</div>
                            <div className="font-size-12">(stock: {maxQuantity.stock}, limit: {maxQuantity.limit})</div>
                        </div>
                    ) : (
                        <div className="badge bg-main text-white rounded-pill">Unlisted</div>
                    )
                }
            </div>
        </li>
    )
}