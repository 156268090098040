import React, { useEffect, useContext } from 'react';
import { useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
// import { IntlProvider } from "react-intl";
import PrivateLayout from './Private';
import PublicLayout from './Public';
import { GlobalContext } from 'store/context/GlobalContext';

function Layout(props) {
    const { children } = props;
    const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
    // const messages = useSelector((state) => state.locales.intl.messages);
    const context = useContext(GlobalContext);
    const { background } = context.pageState;
    
    useEffect(() => {
        background && document.body.classList.add(background);
        return () => background && document.body.classList.remove(background);
    }, [background]);

    return (
        // <IntlProvider key={navigator.language} locale={navigator.language} messages={messages}>
        //     {
        //         isAuthenticated ? (
        //             <PrivateLayout {...props}>{children}</PrivateLayout>
        //         ) : (
        //             <PublicLayout {...props}>{children}</PublicLayout>
        //         )
        //     }
        // </IntlProvider>

        isAuthenticated ? (
            <PrivateLayout {...props}>{children}</PrivateLayout>
        ) : (
            <PublicLayout {...props}>{children}</PublicLayout>
        )
    )
}
  
export default withRouter(Layout);