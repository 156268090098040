import React, { useState, useEffect, useRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { GlobalContext } from 'store/context/GlobalContext';
import MenuIcon from 'layout/components/Header/MenuIcon';
import UserPopper from 'layout/components/Header/UserPopper';
import CartPopper from 'layout/components/Header/CartPopper';
import LogoImage from 'components/LogoImage';
import UnitBox from './UnitBox';
import SearchBar from './SearchBar';

const Nav = () => {
    const location = useLocation();
    const context = useContext(GlobalContext);
    const { brandsList, menuToggle, setMenuToggle } = context;
    const menuRef = useRef(null);
    const [ brandsMenu, setBrandsMenu ] = useState([]);

    useEffect(() => {
        let result = [];
        brandsList.map((item, key) => {
            result.push({
                label: item.name,
                link: {
                    pathname: `/brands/${item.slug}`,
                },
            })
        })
        setBrandsMenu(result);
    }, [brandsList])

    const NavMenu = [
        {
            label: "Home",
            link: "/home",
            activeLink: "/home",
        },
        {
            label: "Inventory",
            link: "/inventory",
            activeLink: "/inventory",
        },
        {
            label: "Brands",
            activeLink: "/brands",
            children: brandsMenu,
        },
    ]

    useEffect(() => {
        if(menuToggle) {
            document.body.classList.add('scroll-lock');
        } else {
            document.body.classList.remove('scroll-lock');
        }
    }, [menuToggle])

    useEffect(() => {
        setMenuToggle(false);
    }, [location])

    const handleToggle = (e) => {
        e.stopPropagation();
        if(!menuRef.current.contains(e.target)) {
            setMenuToggle(false);
        }
    }

    return (<>
        <nav className={`nav-bar${menuToggle ? ' open' : ''}`}>
            <Link className="logo-container" to="/">
                <LogoImage theme="pink"/>
            </Link>
            <div className="nav-container" onClick={handleToggle}>
                <div className="nav-menu" ref={menuRef}>
                    <div className="menu-head">
                        <i className="menu-close" onClick={() => setMenuToggle(false)}></i>
                    </div>
                    <div className="menu-content">
                        <ul className="nav-list">
                            {
                                NavMenu.map((item, index) =>
                                    <li className={`nav-item${location.pathname.startsWith(item.activeLink) ? ` active` : ''}`} key={index}>
                                        { item.link ? <Link className="nav-label" to={item.link}>{item.label}</Link> : <UnitBox item={item} /> }
                                    </li>
                                )
                            }
                        </ul>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center">
                <SearchBar />
                <UserPopper sx={{width: "24px", height: "24px", textTransform: "uppercase", cursor: "pointer", fontSize: "14px"}} />
                <CartPopper sx={{marginLeft: "18px", cursor: "pointer"}} />
                <MenuIcon sx={{marginLeft: "18px"}} />
            </div>
        </nav>
    </>)
}

export default Nav;