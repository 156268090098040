import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import { GlobalContext } from 'store/context/GlobalContext';
import useKeyboard from 'components/useKeyboard';
import { addOrderItem } from 'services/authService';

const ProductList = (props) => {
    const viewMode = "list";
    const { data, setData, loading, fetchOrder, fetchProductList } = props;

    return (
        loading ? (
            <PageLoading viewMode={viewMode} />
        ) : (
            data.length > 0 ? (
                <ul className={`order-product-list view-mode-${viewMode}`}>
                    { data.map((item, index) => <ProductItem item={item} setData={setData} fetchOrder={fetchOrder} fetchProductList={fetchProductList} key={index} />)}
                </ul>
            ) : (
                <div className="text-center" style={{margin: "100px 0"}}>
                    <i className="fal fa-file-search fa-5x"></i>
                    <div className="font-size-18 text-main mgt-4">Product not found</div>
                </div>
            )
        )
    )
}

const PageLoading = ({viewMode}) => {
    return (
        <ul className={`order-product-list view-mode-${viewMode}`}>
            {
                Array(parseInt(12)).fill().map((item, index) =>
                    <li className="product-item" key={index}>
                        <div className="product-container">
                            <div className="product-image" style={{backgroundImage: "none"}}>
                                <Skeleton variant="rectangular" height="100%" width="100%" style={{position: "absolute", top: "0", left: "0"}} />
                            </div>
                            <div className="product-content">
                                <div className="product-info">
                                    <div className="product-category">
                                        <Skeleton variant="rectangular" height="12px" width="50px" style={{borderRadius: "4px"}} />
                                    </div>
                                    <div className="product-name">
                                        <Skeleton variant="rectangular" height="12px" width="80px" style={{borderRadius: "4px", marginTop: "12px"}} />
                                    </div>
                                    <div className="product-name">
                                        <Skeleton variant="rectangular" height="12px" width="80px" style={{borderRadius: "4px", marginTop: "12px"}} />
                                    </div>
                                </div>
                                <div className="product-btn">
                                    <Skeleton variant="rectangular" height="30px" width="100%" style={{borderRadius: "50px"}} />
                                </div>
                            </div>
                        </div>
                    </li>
                )
            }
        </ul>
    )
}

const ProductItem = (props) => {
    const { uuid } = useParams();
    const { item, setData, fetchOrder, fetchProductList } = props;
    const { handleKeyDown } = useKeyboard();
    const [ maxQuantity, setMaxQuantity ] = useState({maximum: null, limit: 0, stock: 0});
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;

    const handleMaximum = useCallback(() => {
        // 情況一 前台有人手動退單
        // stock 30
        // safety_limit 20
        // limit 2
        // order_limit_quantity 1
        // (maximum: 1, stock: 0, limit: 1)

        // 情況二 後台reset過限購或沒買過限購且庫存高於安全值
        // stock 30
        // safety_limit 20
        // limit 2
        // order_limit_quantity 0
        // (maximum: 12, stock: 10, limit: 2)

        // 情況三 後台reset過限購或沒買過限購且庫存低於安全值
        // stock 10
        // safety_limit 20
        // limit 2
        // order_limit_quantity 0
        // (maximum: 2, stock: 0, limit: 2)

        // 情況四 買滿限購
        // stock 10
        // safety_limit 20
        // limit 2
        // order_limit_quantity 2
        // (maximum: 0, stock: 0, limit: 0)

        //原始 o_stock 20
        //原始 o_safety_limit 0
        //原始 o_limit 0
        // (maximum: 20, stock: 20, limit: 0, cart: 0) o_stock - cart = stock
        // (maximum: 10, stock: 10, limit: 0, cart: 10) o_stock - cart = stock
        // (maximum: 9, stock: 9, limit: 0, cart: 11) o_stock - cart = stock
        // (maximum: 8, stock: 8, limit: 0, cart: 12) o_stock - cart = stock
        // (maximum: 0, stock: 0, limit: 0, cart: 20) o_stock - cart = stock

        //原始 o_stock 20
        //原始 o_safety_limit 10
        //原始 o_limit 2
        //原始 o_order_limit_quantity 0
        // (maximum: 12, stock: 10, limit: 2, cart: 0) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 7, stock: 5, limit: 2, cart: 5) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 2, stock: 0, limit: 2, cart: 10) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 1, stock: 0, limit: 1, cart: 11) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 0, stock: 0, limit: 0, cart: 12) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0

        //原始 o_stock 10
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_order_limit_quantity 0
        // (maximum: 5, stock: 0, limit: 5, cart: 0) stock = 0
        // (maximum: 4, stock: 0, limit: 4, cart: 1) stock = 0
        // (maximum: 3, stock: 0, limit: 3, cart: 2) stock = 0
        // (maximum: 0, stock: 0, limit: 0, cart: 5) stock = 0

        //原始 o_stock 10
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_order_limit_quantity 1
        // (maximum: 4, stock: 0, limit: 4, cart: 0) stock = 0
        // (maximum: 3, stock: 0, limit: 3, cart: 1) stock = 0
        // (maximum: 2, stock: 0, limit: 2, cart: 2) stock = 0
        // (maximum: 1, stock: 0, limit: 1, cart: 3) stock = 0
        // (maximum: 0, stock: 0, limit: 0, cart: 4) stock = 0

        //原始 o_stock 10
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_order_limit_quantity 5
        // (maximum: 0, stock: 0, limit: 0, cart: 0) stock = 0

        let maximum = item.stock; //沒限購情況 maximum = 庫存
        let stock = item.stock;
        if(item.limit > 0 && item.safety_limit > 0){
            //有限購情況下
            if(item.order_limit_quantity < item.limit){
                if(item.order_limit_quantity > 0) {
                    //有買過限購但沒有超出限購量
                    maximum = item.limit-item.order_limit_quantity;
                    stock = 0;
                }else if(item.order_limit_quantity === 0) {
                    //沒有買過任何限購商品
                    if(item.stock > item.safety_limit){
                        //且庫存 > 安全值
                        maximum = item.stock-item.safety_limit+item.limit-item.order_limit_quantity;
                        stock = item.stock-item.safety_limit > 0 ? item.stock-item.safety_limit : 0;
                    }else{
                        if(item.stock <= item.limit){
                            maximum = item.stock;
                        }else{
                            maximum = item.limit;
                        }
                        stock = 0;
                    }
                }
            }else{
                //買滿限購量
                maximum = 0;
                stock = 0;
                setData(item, "0");
            }
        }

        setMaxQuantity({...maxQuantity, maximum: maximum, stock: stock, limit: maximum - stock});
    }, [item])

    useEffect(() => {
        handleMaximum();
    }, [handleMaximum])

    const handleAddOrder = () => {
        setLoader(true);
        addOrderItem(uuid, {uuid: item.uuid, quantity: item.quantity}).then(res => {
            setSnack({open: true, text: res.messages});
            fetchOrder();
            fetchProductList();
            setLoader(false);
        }).catch(err => {
            setSnack({open: true, theme: 'danger', text: err.messages.error});
            setLoader(false);
        })
    }

    const handleChange = (value) => {
        if(parseInt(value) > maxQuantity.maximum){
            setData(item, parseInt(maxQuantity.maximum));
        }else{
            setData(item, parseInt(value))
        }
    }

    return (<>
        <li className="order-product-item">
            <div className="product-container">
                {
                    (item.image !== "" && item.image) ? (
                        <div className="product-image" style={{backgroundImage: `url(${item.image})`}}></div>
                    ) : (
                        <div className="product-image"></div>
                    )
                }
                <div className="product-content">
                    <div className="product-info">
                        <div className="product-name">SKU：{item.sku}</div>
                        <div className="product-name">{item.name}</div>
                        <div className="product-name">{item.zh_name}</div>
                    </div>
                </div>
            </div>
            <div className="quantity-container d-flex align-items-center justify-content-end">
                {/* <div className="mgr-2 font-size-12 text-main">Maximum: {item.stock > item.safety_limit ? item.stock-item.safety_limit+item.limit : item.limit}</div> */}
                <div className="quantity-box">
                    <button type="button" className="quantity-btn" onClick={() => parseInt(item.quantity) > 1 && setData(item, parseInt(item.quantity) - 1)} disabled={parseInt(item.quantity) <= 1}>-</button>
                    <input
                        tabIndex="1"
                        type="number"
                        className="quantity-input"
                        pattern="[0-9]*"
                        inputMode="numeric"
                        autoComplete="off"
                        spellCheck="false"
                        value={item.quantity}
                        onWheel={(e) => e.target.blur()}
                        onChange={(e) => handleChange(e.target.value)}
                        onKeyDown={(e) => handleKeyDown(e, { number: true })}
                        onBlur={(e) => (e.target.value === "" || parseInt(e.target.value) < 1) ? setData(item, "1") : setData(item, parseFloat(e.target.value)) }
                    />
                    <button
                        type="button"
                        className="quantity-btn"
                        onClick={() => setData(item, parseInt(item.quantity)+1)}
                        disabled={parseInt(item.quantity) >= maxQuantity.maximum}
                    >+</button>
                </div>
            </div>
            <div className="pdt-3 d-flex align-items-center justify-content-between">
                <div className="text-main">
                    <div className="fw-bold">Maximum：{maxQuantity.maximum}</div>
                    <div className="font-size-12">(stock: {maxQuantity.stock}, limit: {maxQuantity.limit})</div>
                </div>
                <div className="product-btn">
                    <button type="button" onClick={() => handleAddOrder()}>{!isNaN(item.quantity) ? `$ ${Math.round(item.price*item.quantity*10000)/10000}` : '$ 0'}</button>
                </div>
            </div>
        </li>
    </>)
}

export default ProductList;