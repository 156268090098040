import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment/moment.js';
import { getOrder } from 'services/authService';
import Pagination from '@mui/material/Pagination';
import Skeleton from '@mui/material/Skeleton';
import { deleteOrderItem } from 'services/authService';
import ConfirmButton from 'components/ConfirmButton';
import { GlobalContext } from 'store/context/GlobalContext';

export default function History() {
    let mounted = true;
    const [ data, setData ] = useState([]);
    const [ paginationParams, setPaginationParams ] = useState({count: 0, activePage: 0, itemsCountPerPage: 0});
    const [ loading, setLoading ] = useState(true);
    const context = useContext(GlobalContext);
    const { setLoader } = context;

    const fetchData = useCallback(async(page) => {
        setLoading(true);
        let result = await getOrder({page: page});
        if(mounted){
            setData(result.data);
            setPaginationParams({count: result.total, activePage: result.current_page, itemsCountPerPage: result.per_page});
            setLoading(false);
        }
    }, [mounted])

    useEffect(() => {
        fetchData(1);
        return () => mounted = false;
    }, [fetchData])

    const handlePageChange = (event,idx) => {
        setPaginationParams({...paginationParams, activePage: idx});
        fetchData(idx);
    }

    const handleDelete = (index, uuid) => {
        let result = Array.from(data);
        setLoader(true);
        deleteOrderItem(uuid).then(res => {
            result.splice(index, 1);
            setData(result);
            setLoader(false);
        });
    }

    return (<>
        <div className="history-page">
            <div className="history-container">
                {
                    loading ? (
                        <PageLoading />
                    ) : (
                        data.length > 0 ? (<>
                            <div className="font-size-20 fw-bold text-main mgb-3">History Order</div>
                            <ul className="history-list">
                                {
                                    data.map((item, index) =>
                                        <li className="history-item" key={index}>
                                            <div className="d-flex justify-content-between align-items-start">
                                                <div>
                                                    <div className="font-size-16">Order#{item.order_number}</div>
                                                    <div className="text-secondary mgt-1">Status：<span className="text-capitalize">{item.status}</span></div>
                                                    <div className="text-secondary mgt-1 font-size-12">{moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")}</div>
                                                </div>
                                                <div className="font-size-18">${item.total_price >= 0 ? item.total_price : "0"}</div>
                                            </div>
                                            <div className="d-flex justify-content-end mgt-2">
                                                <Link to={`/history/${item.uuid}`} className="border-0 bg-main text-white rounded-1 pdy-1 pdx-4 d-block">{item.status === "pending" ? "Edit" : "View"}</Link>
                                                {
                                                    item.status === "pending" &&
                                                    <ConfirmButton
                                                        className="border-0 bg-main text-white rounded-1 pdy-1 pdx-4 mgl-2"
                                                        onSuccess={() => handleDelete(index, item.uuid)}
                                                        confirmText="Are you sure you want to delete this order?"
                                                        confirm={{show: true, text: 'Yes'}}
                                                        cancel={{show: true, text: 'Cancel'}}
                                                    >
                                                        Delete
                                                    </ConfirmButton>
                                                }
                                            </div>
                                        </li>
                                    )
                                }
                            </ul>
                        </>) : (
                            <div className="text-center" style={{margin: "100px 0"}}>
                                <i className="fal fa-clipboard-list fa-5x"></i>
                                <div className="font-size-18 text-main mgt-3">No history data</div>
                                <div className="text-secondary mgt-3">Looks like you haven't ordered anything to your cart yet.</div>
                                <Link className=" pdy-2 pdx-4 mgt-6 text-white d-inline-block bg-main" to="/inventory">Order Now</Link>
                            </div>
                        )
                    )
                }
                {
                    data.length > 0 && paginationParams.count !== 0 && paginationParams.count !== undefined && paginationParams.itemsCountPerPage !== 0 &&
                    <div className="mgt-6 pdx-3 text-center">
                        <Pagination count={Math.ceil(paginationParams.count/paginationParams.itemsCountPerPage)} page={paginationParams.activePage} defaultPage={1} onChange={handlePageChange}/>
                    </div>
                }
            </div>
        </div>
    </>)
}

const PageLoading = () => {
    return (<>
        <div className="font-size-20 fw-bold text-main mgb-3">History Order</div>
        <ul className="history-list">
            {
                Array(parseInt(12)).fill().map((item, index) =>
                    <li className="history-item" key={index}>
                        <div className="d-flex justify-content-between align-items-start">
                            <div>
                                <div className="font-size-16"><Skeleton variant="rectangular" height="14px" width="140px" style={{borderRadius: "4px"}} /></div>
                                <div className="text-secondary mgt-1"><Skeleton variant="rectangular" height="12px" width="110px" style={{borderRadius: "4px", marginTop: "18px"}} /></div>
                                <div className="text-secondary mgt-1 font-size-12"><Skeleton variant="rectangular" height="12px" width="110px" style={{borderRadius: "4px", marginTop: "14px"}} /></div>
                            </div>
                            <div className="font-size-18"><Skeleton variant="rectangular" height="20px" width="70px" style={{borderRadius: "4px"}} /></div>
                        </div>
                        <div className="d-flex justify-content-end mgt-2">
                            <Skeleton variant="rectangular" height="31px" width="65px" style={{borderRadius: "4px"}} />
                            <Skeleton variant="rectangular" height="31px" width="80px" style={{borderRadius: "4px", marginLeft: "10px"}} />
                        </div>
                    </li>
                )
            }
        </ul>
    </>)
}