export default function useKeyboard() {

    const handleKeyDown = (e, params) => {
        let keyCode = e.keyCode;
        let code = e.code;

        let status = false;

        if(keyCode === 8 || keyCode === 9) status = true;

        if(keyCode >= 37 && keyCode <= 40) status = true;

        if(params && params.number) {
            if((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) status = true;
        }

        if(params && params.float) {
            if(keyCode === 190 || keyCode === 110 || keyCode === 46) status = true;
        }

        if(params && params.string) {
            
        }

        if(params && params.sign) {
            
        }

        if(!status) e.preventDefault();
    }

    return { handleKeyDown };
}

