//Auth-------------------------------------------------------
import Login from 'pages/Auth/Login';

// Home------------------------------------------------------
import UserHome from 'pages/Home';

// Brands----------------------------------------------------
import Brands from 'pages/Brands';

// Inventory-------------------------------------------------
import Inventory from 'pages/Inventory';

// Cart------------------------------------------------------
import Cart from 'pages/Cart';

// History------------------------------------------------------
import History from 'pages/History';
import OrderDetail from 'pages/History/OrderDetail';

// Search----------------------------------------------------
import Search from 'pages/Search';

const routes = [
    {
        path: '/',
        exact: true,
        component: Login,
        hasHeader: false,
        hasFooter: false,
        // background: "white",
    },
    {
        path: '/login',
        auth: false,
        component: Login,
        hasHeader: true,
        hasFooter: false,
        // subTitle: "",
        // background: "white",
    },
    {
        path: '/home',
        exact: true,
        auth: true,
        component: UserHome,
        hasHeader: true,
        // mainTitle: '首頁',
    },
    {
        path: '/brands/:brand',
        exact: true,
        auth: true,
        component: Brands,
        hasHeader: true,
        // mainTitle: '首頁',
    },
    {
        path: '/inventory',
        exact: true,
        auth: true,
        component: Inventory,
        hasHeader: true,
        // mainTitle: '首頁',
    },
    {
        path: '/cart',
        exact: true,
        auth: true,
        component: Cart,
        hasHeader: true,
        // mainTitle: '首頁',
    },
    {
        path: '/history',
        exact: true,
        auth: true,
        component: History,
        hasHeader: true,
        // mainTitle: '首頁',
    },
    {
        path: '/history/:uuid',
        exact: true,
        auth: true,
        component: OrderDetail,
        hasHeader: true,
        // mainTitle: '首頁',
    },
    {
        path: '/search',
        exact: true,
        auth: true,
        component: Search,
        hasHeader: true,
        // mainTitle: '首頁',
    },
];

export default routes;