import React, { useContext } from 'react';
import { GlobalContext } from 'store/context/GlobalContext';

const HamburgerMenu = (props) => {
    const context = useContext(GlobalContext);
    const { sx } = props;

    return (
        <div className={`hamburger-box${context.menuToggle ? ` active` : ''}`} style={sx} onClick={() => context.setMenuToggle(!context.menuToggle)}>
            <div className="hamburger-menu">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    )
}

export default HamburgerMenu;
