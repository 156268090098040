import { useEffect, useState } from 'react';
import { createPortal } from "react-dom";

const Portal = ({ children, id }) => {
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);

        return () => setMounted(false);
    }, [])

    return mounted 
        ? createPortal(children, document.querySelector(id ? id : "#modal-root"))
        : null
}

export default Portal;