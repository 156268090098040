import React, { useEffect, useCallback, useContext } from 'react';
import { GlobalContext } from 'store/context/GlobalContext';
import { useDispatch, useSelector } from 'react-redux';
import * as action from "store/actions";
import Header from './components/Header';
import Footer from './components/Footer';
import { fetchUser, getBrandsList, getCart } from 'services/authService';

export default function Private(props) {
    let mounted = true;
    const { children } = props;
    const dispatch = useDispatch();
    const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
    const user = useSelector((state) => state.Auth.user);
    const context = useContext(GlobalContext);
    const { setBrandsList, freshCart, setFreshCart } = context;

    useEffect(() => {
        const fetchBrandsList = async() => {
            let result = await getBrandsList();
            if(mounted){
                setBrandsList(result.data);
            }
        }
        fetchBrandsList();

        return () => mounted = false;
    }, [])

    useEffect(() => {
		if (isAuthenticated && !user.username) {
			dispatch(fetchUser());
		}
    }, [isAuthenticated]);
    

    useEffect(() => {
        if(!freshCart) return;
        
        const fetchCart = async() => {
            let result = await getCart();
            if(freshCart){
                dispatch(action.updateCart(result.data));
                setFreshCart(false);
            }
        }

        fetchCart();

        return () => setFreshCart(false);
    }, [freshCart])

    return (<>
        <Header/>
		<main className={!context.pageState.header ? 'pt-0' : ''}>
			{ children }
		</main>
        {/* <Footer/> */}
    </>)
}
