import React from "react";
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import { GlobalProvider } from 'store/context/GlobalContext';
import routes from './routes';
import Layout from 'layout';
import PrivateRoute from './Private';
import PublicRoute from './Public';
import Loader from 'components/Loader';
import SnackBar from "components/SnackBar";

const Routes = () => {
    
    return (
        <GlobalProvider>
            <Router>
                <Layout>
                    <Switch>
                        {routes.map((route, i) => (
                            route.auth ? (
                                <PrivateRoute key={i} {...route}/>
                            ) : (
                                <PublicRoute key={i} {...route}/>
                            )
                        ))}
                    </Switch>
                </Layout>
                <SnackBar />
                <Loader />
            </Router>
        </GlobalProvider>
    );
}

export default Routes;
