import {
    UPDATE_CART,
    CLEAR_CART
} from 'store/action-types';

const cart = {
    count: 0,
    items: [],
    subTotal: 0,
    total: 0,
    totalQuantity: 0,
    totalWeight: 0,
    loading: true,
};

const initialState = {
    ...cart
};

const Cart = (state = initialState, {type, payload = null}) => {
    switch(type){
        case UPDATE_CART:
            return updateCart(state, payload);
        case CLEAR_CART:
            return clearCart(state);
        default:
            return state;
    }
};

const updateCart = (state, payload) => {
    return {
        ...state,
        ...payload,
        loading: false,
    }
}

const clearCart = (state) => {
    return {
        ...state,
        ...cart,
        loading: false,
    }
}

export default Cart;