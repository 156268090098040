import React, { useEffect, useState, useCallback, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as action from "store/actions";
import Skeleton from '@mui/material/Skeleton';
import LightBox from '../LightBox';
import ConfirmBox from '../ConfirmBox';
import { GlobalContext } from 'store/context/GlobalContext';
import { getCart, addToCart } from 'services/authService';
import Spinner from 'react-bootstrap/Spinner';
import useKeyboard from 'components/useKeyboard';

const ProductList = (props) => {
    let mounted = true;
    const dispatch = useDispatch();
    const viewMode = useSelector((state) => state.ProductViewMode.viewMode);
    const { data, loading } = props;
    const cartData = useSelector((state) => state.Cart);

    const fetchCart = useCallback(async() => {
        let result = await getCart();
        if(mounted){
            dispatch(action.updateCart(result.data));
        }
    }, [mounted])

    return (
        loading ? (
            <PageLoading viewMode={viewMode} />
        ) : (
            data.length > 0 ? (
                <ul className={`product-list view-mode-${viewMode}`}>
                    { data.map((item, index) => <ProductItem cartData={cartData} fetchCart={fetchCart} item={item} key={index} />)}
                </ul>
            ) : (
                <div className="text-center" style={{margin: "100px 0"}}>
                    <i className="fal fa-file-search fa-5x"></i>
                    <div className="font-size-18 text-main mgt-4">Product not found</div>
                </div>
            )
        )
    )
}

const PageLoading = ({viewMode}) => {
    return (
        <ul className={`product-list view-mode-${viewMode}`}>
            {
                Array(parseInt(12)).fill().map((item, index) =>
                    <li className="product-item" key={index}>
                        <div className="product-container">
                            <div className="product-image" style={{backgroundImage: "none"}}>
                                <Skeleton variant="rectangular" height="100%" width="100%" style={{position: "absolute", top: "0", left: "0"}} />
                            </div>
                            <div className="product-content">
                                <div className="product-info">
                                    <div className="product-category">
                                        <Skeleton variant="rectangular" height="12px" width="50px" style={{borderRadius: "4px"}} />
                                    </div>
                                    <div className="product-name">
                                        <Skeleton variant="rectangular" height="12px" width="80px" style={{borderRadius: "4px", marginTop: "12px"}} />
                                    </div>
                                    <div className="product-name">
                                        <Skeleton variant="rectangular" height="12px" width="80px" style={{borderRadius: "4px", marginTop: "12px"}} />
                                    </div>
                                </div>
                                <div className="product-btn">
                                    <Skeleton variant="rectangular" height="30px" width="100%" style={{borderRadius: "50px"}} />
                                </div>
                            </div>
                        </div>
                    </li>
                )
            }
        </ul>
    )
}

const ProductItem = (props) => {
    const { item, cartData, fetchCart } = props;
    const [ lightboxToggle, setLightboxToggle ] = useState(false);
    const [ quantity, setQuantity ] = useState("1");
    const [ maxQuantity, setMaxQuantity ] = useState({maximum: null, limit: 0, stock: 0});
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);
    const [ loading, setLoading ] = useState(false);
    const [ confirmText, setConfirmText ] = useState();
    const { handleKeyDown } = useKeyboard();
    const context = useContext(GlobalContext);
    const { setSnack, setFreshCart } = context;
    const [ cartItem, setCartItem ] = useState({});

    const handleMaximum = useCallback(() => {
        // 情況一 前台有人手動退單
        // stock 30
        // safety_limit 20
        // limit 2
        // order_limit_quantity 1
        // (maximum: 1, stock: 0, limit: 1)

        // 情況二 後台reset過限購或沒買過限購且庫存高於安全值
        // stock 30
        // safety_limit 20
        // limit 2
        // order_limit_quantity 0
        // (maximum: 12, stock: 10, limit: 2)

        // 情況三 後台reset過限購或沒買過限購且庫存低於安全值
        // stock 10
        // safety_limit 20
        // limit 2
        // order_limit_quantity 0
        // (maximum: 2, stock: 0, limit: 2)

        // 情況四 買滿限購
        // stock 10
        // safety_limit 20
        // limit 2
        // order_limit_quantity 2
        // (maximum: 0, stock: 0, limit: 0)

        //原始 o_stock 20
        //原始 o_safety_limit 0
        //原始 o_limit 0
        // (maximum: 20, stock: 20, limit: 0, cart: 0) o_stock - cart = stock
        // (maximum: 10, stock: 10, limit: 0, cart: 10) o_stock - cart = stock
        // (maximum: 9, stock: 9, limit: 0, cart: 11) o_stock - cart = stock
        // (maximum: 8, stock: 8, limit: 0, cart: 12) o_stock - cart = stock
        // (maximum: 0, stock: 0, limit: 0, cart: 20) o_stock - cart = stock

        //原始 o_stock 20
        //原始 o_safety_limit 10
        //原始 o_limit 2
        //原始 o_order_limit_quantity 0
        // (maximum: 12, stock: 10, limit: 2, cart: 0) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 7, stock: 5, limit: 2, cart: 5) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 2, stock: 0, limit: 2, cart: 10) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 1, stock: 0, limit: 1, cart: 11) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0
        // (maximum: 0, stock: 0, limit: 0, cart: 12) stock = o_stock-o_safety_limit-cart > 0 ? o_stock-o_safety_limit-cart : 0

        //原始 o_stock 10
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_order_limit_quantity 0
        // (maximum: 5, stock: 0, limit: 5, cart: 0) stock = 0
        // (maximum: 4, stock: 0, limit: 4, cart: 1) stock = 0
        // (maximum: 3, stock: 0, limit: 3, cart: 2) stock = 0
        // (maximum: 0, stock: 0, limit: 0, cart: 5) stock = 0

        //原始 o_stock 10
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_order_limit_quantity 1
        // (maximum: 4, stock: 0, limit: 4, cart: 0) stock = 0
        // (maximum: 3, stock: 0, limit: 3, cart: 1) stock = 0
        // (maximum: 2, stock: 0, limit: 2, cart: 2) stock = 0
        // (maximum: 1, stock: 0, limit: 1, cart: 3) stock = 0
        // (maximum: 0, stock: 0, limit: 0, cart: 4) stock = 0

        //原始 o_stock 10
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_order_limit_quantity 5
        // (maximum: 0, stock: 0, limit: 0, cart: 0) stock = 0

        let cartQuantity = cartItem ? cartItem.quantity : 0;

        let maximum = item.stock-cartQuantity; //沒限購情況 maximum = 庫存
        let stock = item.stock-cartQuantity;
        if(item.limit > 0 && item.safety_limit > 0){
            //有限購情況下
            if(item.order_limit_quantity < item.limit){
                if(item.order_limit_quantity > 0) {
                    //有買過限購但沒有超出限購量
                    maximum = item.limit-item.order_limit_quantity-cartQuantity;
                    stock = 0;
                }else if(item.order_limit_quantity === 0) {
                    //沒有買過任何限購商品
                    if(item.stock > item.safety_limit){
                        //且庫存 > 安全值
                        maximum = item.stock-item.safety_limit+item.limit-item.order_limit_quantity-cartQuantity;
                        stock = item.stock-item.safety_limit-cartQuantity > 0 ? item.stock-item.safety_limit-cartQuantity : 0;
                    }else{
                        if(item.stock <= item.limit){
                            maximum = item.stock-cartQuantity;
                        }else{
                            maximum = item.limit-cartQuantity;
                        }
                        stock = 0;
                    }
                }
            }else{
                //買滿限購量
                maximum = 0;
                stock = 0;
                setQuantity("0");
            }
        }

        setMaxQuantity({...maxQuantity, maximum: maximum, stock: stock, limit: maximum - stock});
    }, [cartItem])

    useEffect(() => {
        setCartItem(cartData.items.find(e => e.uuid === item.uuid));
    }, [cartData])

    useEffect(() => {
        lightboxToggle && (maxQuantity.maximum < 1 ? setQuantity("0") : setQuantity("1"));
    }, [lightboxToggle])

    useEffect(() => {
        handleMaximum();
    }, [handleMaximum])

    const handleClose = () => {
        setLightboxToggle(false);
        setTimeout(() => {
            maxQuantity.maximum < 1 ? setQuantity("0") : setQuantity("1")
        }, 300);
    }

    const handleChange = (value) => {
        if(parseInt(value) > maxQuantity.maximum){
            setQuantity(maxQuantity.maximum);
        }else{
            setQuantity(value);
        }
    }

    const handleAddCart = () => {
        setLoading(true);
        addToCart(item.uuid, {quantity: quantity}).then(res => {
            setFreshCart(true);
            handleClose();
            fetchCart();
            setConfirmText(<>
                <div className="text-center text-success font-size-22 mgb-2"><i className="fas fa-check-circle mgr-1"></i>Success</div>
                <div>{quantity} x『{item.name} {item.zh_name}』 has been added to your shopping cart</div>
            </>)
            setTimeout(() => {
                setLoading(false);
                setConfirmBoxToggle(true);
            }, 300);
        }).catch(err => {
            setConfirmText(<>
                <div className="text-center text-danger font-size-22 mgb-2"><i className="fas fa-exclamation-circle mgr-1"></i>Fail</div>
                <div>{err.messages.error}</div>
            </>);
            setTimeout(() => {
                setLoading(false);
                setConfirmBoxToggle(true);
            }, 300);
            // setSnack({open: true, text: err.messages.error});
        })
    }

    return (<>
        <li className={`product-item${item.stock <= 0 ? ' sold-out' : (item.limit > 0 && item.safety_limit > 0 && maxQuantity.limit < 1 && item.order_limit_quantity > 0) ? ' limited-out' : ''}`}>
            <div className="product-container">
                {
                    (item.image !== "" && item.image) ? (
                        <div className="product-image" style={{backgroundImage: `url(${item.image})`}}></div>
                    ) : (
                        <div className="product-image"></div>
                    )
                }
                <div className="product-content">
                    <div className="product-info">
                        <div className="product-category">{item.category.name}</div>
                        <div className="product-name">{item.name}</div>
                        <div className="product-name">{item.zh_name}</div>
                    </div>
                    <div className="product-btn">
                        <button type="button" onClick={() => setLightboxToggle(true)}>{item.stock > 0 ? (item.limit > 0 && item.safety_limit > 0 && maxQuantity.limit < 1 && item.order_limit_quantity > 0) ? '' : `$ ${item.price}` : ''}</button>
                    </div>
                </div>
            </div>
            <div className="product-brands">{item.brands.map((brand, index) => <div className="badge bg-main fw-normal text-white" key={index}>{brand.name}</div>)}</div>
        </li>
        <LightBox
            open={lightboxToggle}
            callback={() => handleClose()}
            className="product-light-box"
            cross
        >
            <div className="light-box-header pd-3 bg-white">
                <div className="text-main fw-bold font-size-16">Add To Cart</div>
            </div>
            <div className="light-box-body pdx-3 bg-white">
                <div className="d-flex align-items-stretch">
                    {
                        (item.image !== "" && item.image) ? (
                            <div className={`item-image${item.stock <= 0 ? ` sold-out` : ''}`} style={{backgroundImage: `url(${item.image})`}}></div>
                        ) : (
                            <div className={`item-image${item.stock <= 0 ? ` sold-out` : ''}`}></div>
                        )
                    }
                    <div className="d-flex flex-column justify-content-between mgl-3" style={{flex: "1"}}>
                        <div className="item-info">
                            <div className="item-category">{item.category.name}</div>
                            <div className="item-name">{item.name}</div>
                            <div className="item-name">{item.zh_name}</div>
                        </div>
                        {
                            item.stock > 0 &&
                            <div className="d-flex align-items-center">
                                <div className="quantity-box">
                                    <button type="button" className="quantity-btn" onClick={() => parseInt(quantity) > 1 && setQuantity(parseInt(quantity) - 1)} disabled={parseInt(quantity) <= 1}>-</button>
                                    <input
                                        tabIndex="1"
                                        type="number"
                                        className="quantity-input"
                                        pattern="[0-9]*"
                                        inputMode="numeric"
                                        autoComplete="off"
                                        spellCheck="false"
                                        value={quantity}
                                        onWheel={(e) => e.target.blur()}
                                        onChange={(e) => handleChange(e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(e, { number: true })}
                                        onBlur={(e) => (e.target.value === "" || parseInt(e.target.value) < 1) && maxQuantity.maximum > 0 && setQuantity("1")}
                                        readOnly={maxQuantity.maximum === 0 && parseInt(quantity) === 0}
                                    />
                                    <button
                                        type="button"
                                        className="quantity-btn"
                                        onClick={() => parseInt(quantity) < maxQuantity.maximum && setQuantity(parseInt(quantity) + 1)}
                                        disabled={parseInt(quantity) >= maxQuantity.maximum}
                                    >+</button>
                                </div>
                                <div className="mgl-2 font-size-12 text-main">
                                    <div>Maximum: {maxQuantity.maximum ? maxQuantity.maximum : "0"}</div>
                                    <div>(stock: {maxQuantity.stock}, limit: {maxQuantity.limit})</div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="mgy-4">
                    <div className="mgb-1">Brands：{item.brands.map((brand, index) => <span key={index}>{brand.name}{item.brands.length !== index+1 && "、"}</span>)}</div>
                    <div className="mgb-1">Item Description：{item.description ? item.description : "-"}</div>
                    <div className="mgb-1">SKU：{item.sku}</div>
                    <div className="mgb-1">Weight(lbs)：{item.weight}</div>
                    <div className="mgb-1">Dimension(Cu in)：{item.dimension}</div>
                    <div className="mgb-1 text-main fw-bold">Price ($)：{item.price}</div>
                    {/* <div className="text-main fw-bold">Stock: {item.stock}</div> */}
                </div>
            </div>
            <div className="light-box-footer bg-white">
                { item.stock > 0 && <div className="mgb-2 font-size-16 fw-bold">Item Total：$ {(Math.round(item.price*quantity*10000)/10000)}</div> }
                <button type="button" className="add-to-cart-btn" onClick={() => handleAddCart()} disabled={item.stock <= 0 || quantity < 1 || loading}>Add To Cart{loading && <Spinner className="mgl-2" animation="border" size="sm" />}</button>
            </div>
        </LightBox>
        <ConfirmBox
            toggle={confirmBoxToggle}
            setToggle={() => {
                setConfirmBoxToggle(false);
                setTimeout(() => {
                    setQuantity("1");
                }, 300)
            }}
            confirmCallback={() => {
                setConfirmBoxToggle(false);
                setTimeout(() => {
                    setQuantity("1");
                }, 300)
            }}
            confirmText={confirmText}
            confirm={{show: true, text: 'Ok'}}
            cancel={{show: false, text: "Cancel"}}
        />
    </>)
}

export default ProductList;