import React, { useState, useEffect } from 'react';

const initialState = {
    pageState: {
        hasHeader: true,
        hasFooter: false,
        mainTitle: "",
        subTitle: "",
        background: "",
    },
    menuToggle: false,
    userPopperToggle: false,
    cartPopperToggle: false,
    brandsList: [],
    snack: {open: false, theme:'dark', text:''},
    loader: false,
    freshCart: true,
    setPageState: state => {},
    setMenuToggle: state => {},
    setUserPopperToggle: state => {},
    setCartPopperToggle: state => {},
    setViewMode: state => {},
    setBrandsList: state => {},
    setSnack: state => {},
    setLoader: state => {},
    setFreshCart: state => {},
}

export const GlobalContext = React.createContext(initialState);

export const GlobalProvider = (props) => {
    const [ pageState, setPageState ] = useState(initialState.pageState);
    const [ menuToggle, setMenuToggle ] = useState(initialState.menuToggle);
    const [ userPopperToggle, setUserPopperToggle ] = useState(initialState.userPopperToggle);
    const [ cartPopperToggle, setCartPopperToggle ] = useState(initialState.cartPopperToggle);
    const [ brandsList, setBrandsList ] = useState(initialState.brandsList);
    const [ snack, setSnack ] = useState(initialState.snack);
    const [ loader, setLoader ] = useState(initialState.loader);
    const [ freshCart, setFreshCart ] = useState(initialState.freshCart);

    return (
        <GlobalContext.Provider value={{
            pageState, menuToggle, userPopperToggle, cartPopperToggle, brandsList, snack, loader, freshCart,
            setPageState, setMenuToggle, setUserPopperToggle, setCartPopperToggle, setBrandsList, setSnack, setLoader, setFreshCart
        }}>
            { props.children }
        </GlobalContext.Provider>
    )
}