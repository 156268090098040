import React, { useState, useEffect, useCallback, useContext} from 'react';
import moment from 'moment/moment.js';
import Pagination from '@mui/material/Pagination';
import { getNewsList, getEventsList, getEventItem } from 'services/authService';
import LightBox from 'components/LightBox';
import { GlobalContext } from 'store/context/GlobalContext';
import Skeleton from '@mui/material/Skeleton';

export default function Home() {
    return (
        <div className="home-page">
            <div className="home-wrapper">
                <EventsContainer />
                <NewsContainer />
            </div>
        </div>
    );
}


const EventsContainer = () => {
    let mounted = true;
    const [ eventList, setEventList ] = useState({});
    const [ loading, setLoading ] = useState(false);

    const PageLoading = () => {
        return (
            <div className="event-content" style={{overflowX: "hidden"}}>
                <ul className="event-list">
                    {
                        Array(parseInt(6)).fill().map((item, index) =>
                            <li className="event-item" style={{padding: "0"}} key={index}>
                                <Skeleton variant="rectangular" height="100%" width="100%" style={{borderRadius: "20px"}} />
                            </li>
                        )
                    }
                </ul>
            </div>
        )
    }

    useEffect(() => {
        const fetchEventList = async() => {
            setLoading(true);
            let result = await getEventsList();
            if(mounted){
                setEventList(result);
                setLoading(false);
            }
        }

        fetchEventList();
        return () => mounted = false;
    }, [])

    return (
        <div className="event-container">
            <div className="font-size-20 fw-bold text-main mgb-6">Events</div>
                {
                    loading ? (
                        <PageLoading />
                    ) : (
                        <div className="event-content" style={{overflowX: "auto"}}>
                            <ul className="event-list">
                                { eventList.data && eventList.data.map((item, index) => <EventItem item={item} key={index} />) }
                            </ul>
                        </div>
                    )
                }
        </div>
    )
}



const EventItem = ({item}) => {
    let mounted = true;
    const context = useContext(GlobalContext);
    const { setLoader } = context;
    const [ toggle, setToggle ] = useState(false);
    const [ eventItem, setEventItem ] = useState({});

    const fetchEventItem = async(slug) => {
        setLoader(true);
        let result = await getEventItem(slug);
        if(mounted){
            setEventItem(result.data);
            setLoader(false);
            setToggle(true);
        }
    }

    useEffect(() => {
        return () => mounted = false;
    }, [])

    return (<>
        <li className="event-item" style={{backgroundImage: `url(${item.image})`}} onClick={() => fetchEventItem(item.slug)}>
            {item.title}
        </li>
        <LightBox
            open={toggle}
            callback={() => setToggle(false)}
            className="events-light-box"
            cross
        >
            <div className="light-box-header bg-white pd-3 font-size-18 fw-bold">{eventItem.title}</div>
            <div className="light-box-body bg-white pd-3" dangerouslySetInnerHTML={{__html: eventItem.content}}></div>
        </LightBox>
    </>)
}

const NewsContainer = () => {
    let mounted = true;
    const [ newList, setNewsList ] = useState({});
    const [ paginationParams, setPaginationParams ] = useState({count: 0, activePage: 0, itemsCountPerPage: 0});
    const [ loading, setLoading ] = useState(false);

    const fetchNewsList = useCallback(async(page) => {
        setLoading(true);
        let result = await getNewsList({page: page});
        if(mounted){
            setNewsList(result);
            setPaginationParams({count: result.total, activePage: result.current_page, itemsCountPerPage: result.per_page});
            setLoading(false);
        }
    }, [mounted])

    useEffect(() => {
        fetchNewsList();
        return () => mounted = false;
    }, [fetchNewsList])

    const handlePageChange = (event,idx) => {
        setPaginationParams({...paginationParams, activePage: idx});
        fetchNewsList(idx);
    }

    const PageLoading = () => {
        return (
            <ul className="news-list">
                {
                    Array(parseInt(4)).fill().map((item, index) =>
                        <li className="news-item" key={index}>
                            <div className="time"><Skeleton variant="rectangular" height="12px" width="100px" style={{borderRadius: "4px"}} /></div>
                            <div className="content">
                                <Skeleton variant="rectangular" height="15px" width="100%" style={{borderRadius: "4px", marginTop: "15px"}} />
                                <Skeleton variant="rectangular" height="15px" width="150px" style={{borderRadius: "4px", marginTop: "15px"}} />
                            </div>
                        </li>
                    )
                }
            </ul>
        )
    }

    return (
        <div className="news-container">
            <div className="font-size-20 fw-bold text-main mgb-6">News</div>
            {
                loading ? (
                    <PageLoading />
                ) : (<>
                    <ul className="news-list">
                        {
                            newList.data &&
                            newList.data.map((item, index) =>
                                <li className="news-item" key={index}>
                                    <div className="time">{moment(item.created_at).format("MM/DD/YYYY")}</div>
                                    <div className="content">{item.content}</div>
                                </li>
                            )
                        }
                    </ul>
                    {
                        (newList.data && newList.data.length > 0) && paginationParams.count !== 0 && paginationParams.count !== undefined && paginationParams.itemsCountPerPage !== 0 &&
                        <div className="mgt-6 pdx-3 text-center">
                            <Pagination count={Math.ceil(paginationParams.count/paginationParams.itemsCountPerPage)} page={paginationParams.activePage} defaultPage={1} onChange={handlePageChange}/>
                        </div>
                    }
                </>)
            }
        </div>
    )
}