export default function useSearchArray() {
    let result;

    const searchArray = (array, key, value) => {
        array.find((item) => {
            if(item[key] === value) return result = item;
            Object.keys(item).map(objKey => (Array.isArray(item[objKey])) && searchArray(item[objKey], key, value));
        });

        if(result) return result;
    }

    const searchArrayTree = (array, key, value) => {
        let datas = {};

        array.map((item) => {
            let data = {...item};

            item['children'] && delete data['children'];

            if(item['children']) {
                let children = searchArrayTree(item['children'], key, value);
                
                if(Object.keys(children).length < 1 && item[key] !== value) return;
                if(item[key] !== value) data['children'] = children;
            } else {
                if(item[key] !== value) return;
            }

            datas = {...data};
        })

        return datas;
    }
    
    return { searchArray, searchArrayTree }
}
