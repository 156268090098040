import { useState, useContext, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalContext } from 'store/context/GlobalContext';
import { logout } from 'services/authService';
import ConfirmBox from 'components/ConfirmBox';
import Avatar from '@mui/material/Avatar';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import ManageSearchIcon from '@mui/icons-material/ManageSearch';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import LogoutIcon from '@mui/icons-material/Logout';
import Badge from '@mui/material/Badge';

const UserPopper = ({sx}) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.Auth.user);
    const targetRef = useRef(null);
    const context = useContext(GlobalContext);
    const { setSnack, setLoader, userPopperToggle, setUserPopperToggle, setFreshCart } = context;
    const [ confirmBoxToggle, setConfirmBoxToggle] = useState(false);

    const handleLogout = () => {
        setLoader(true);
        dispatch(logout()).then(res => {
            setFreshCart(true);
            setLoader(false);
            setSnack({open: true, text: "登出成功"});
        }).catch(err => {
            setLoader(false);
        })
    }

    if(!user) return <></>

    return (<>
        <ClickAwayListener onClickAway={() => setUserPopperToggle(false)}>
            <div>
                <Badge
                    color="secondary"
                    overlap="circular"
                    variant="dot"
                    anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                    sx={{marginLeft: "18px"}}
                    invisible
                >
                    <Avatar
                        src={user.avatar}
                        sx={sx}
                        onClick={() => setUserPopperToggle(!userPopperToggle)}
                        ref={targetRef}
                    >
                        {user.name && user.name.charAt(0)}
                    </Avatar>
                </Badge>
                <Popper
                    className="avatar-popper"
                    id="avatar-popper"
                    open={userPopperToggle}
                    anchorEl={targetRef.current}
                    transition
                    disablePortal
                >
                    {({ TransitionProps }) => (
                        <Grow
                            style={{ transformOrigin: '50% 0 0'}}
                            timeout={350}
                            {...TransitionProps}
                        >
                            <div className="avatar-container">
                                <div className="popper-header">
                                    <Avatar src={user.avatar} sx={{width: "40px", height: "40px", textTransform: "uppercase"}}>{user.name && user.name.charAt(0)}</Avatar>
                                    <div className="mgl-3">{user.name}</div>
                                </div>
                                <div className="popper-body">
                                    <ul className="popper-tools-list">
                                        {/* <li className="popper-tools-item" onClick={() => {
                                            setUserPopperToggle(false);
                                        }}>
                                            <div className="popper-icon"><AssignmentIndIcon /></div>
                                            <label className="popper-label">Profile</label>
                                        </li> */}
                                        <li className="popper-tools-item" onClick={() => {
                                            setUserPopperToggle(false);
                                            history.push('/history');
                                        }}>
                                            <div className="popper-icon"><ManageSearchIcon /></div>
                                            <label className="popper-label">History</label>
                                            {/* <div className="mgr-3 badge rounded-pill bg-danger">1</div> */}
                                        </li>
                                        <li className="popper-tools-item" onClick={() => {
                                            setUserPopperToggle(false);
                                            setConfirmBoxToggle(true);
                                        }}>
                                            <div className="popper-icon"><LogoutIcon /></div>
                                            <label className="popper-label">Logout</label>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
        <ConfirmBox
            toggle={confirmBoxToggle}
            setToggle={setConfirmBoxToggle}
            confirmCallback={handleLogout}
            confirmText="Are you sure to logout?"
            confirm={{show: true, text: 'Ok'}}
            cancel={{show: true, text: "Cancel"}}
        />
    </>)
}

export default UserPopper;