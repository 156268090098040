import {
    AUTH_CHECK,
    AUTH_LOGIN,
    AUTH_LOGOUT,
    AUTH_REFRESH_TOKEN,
    AUTH_RESET_PASSWORD,
    AUTH_USER,
    UPDATE_LOCALES,
    UPDATE_CART,
    CLEAR_CART,
    UPDATE_PRODUCT_VIEW_MODE,
} from 'store/action-types'

export function authCheck() {
    return {
        type: AUTH_CHECK
    }
}

export function authLogin(payload) {
    return {
        type: AUTH_LOGIN,
        payload
    }
}

export function authLogout() {
    return {
        type: AUTH_LOGOUT
    }
}

export function authRefreshToken(payload) {
    return {
        type: AUTH_REFRESH_TOKEN,
        payload
    }
}
  
export function authResetPassword() {
    return {
        type: AUTH_RESET_PASSWORD,
    }
}

export function authUser(payload) {
    return {
        type: AUTH_USER,
        payload
    }
}

export function updateLocales(payload) {
    return {
        type: UPDATE_LOCALES,
        payload
    }
}

export function updateCart(payload) {
    return {
        type: UPDATE_CART,
        payload
    }
}

export function clearCart() {
    return {
        type: CLEAR_CART,
    }
}

export function updateProductViewMode(payload) {
    return {
        type: UPDATE_PRODUCT_VIEW_MODE,
        payload
    }
}