import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { GlobalContext } from 'store/context/GlobalContext';
import { getOrderItem, updateOrderItem, updateOrderItemProduct, deleteOrderItemProduct } from 'services/authService';
import PullToRefresh from 'react-simple-pull-to-refresh';
import CircularProgress from '@mui/material/CircularProgress';
import useKeyboard from 'components/useKeyboard';
import ConfirmButton from 'components/ConfirmButton';
import ProductListLightbox from 'components/pages/ProductListLightbox';

export default function OrderDetail() {
    let mounted = true;
    const { uuid } = useParams();
    const context = useContext(GlobalContext);
    const { loader, setLoader, setSnack } = context;
    const [ loading, setLoading ] = useState(false);
    const [ note, setNote ] = useState("");
    const [ freshOrder, setFreshOrder ] = useState(false);
    const [ data, setData ] = useState({
        items: [],
    });

    const fetchData = useCallback(async() => {
        await getOrderItem(uuid).then(res => {
            if(mounted){
                setData(res.data);
                setFreshOrder(false);
                setNote(res.data.details ? res.data.details : '');
                setLoader(false);
            }
        }).catch(err => {
            if(mounted){
                setData({
                    items: [],
                });
                setFreshOrder(false);
                setLoader(false);
            }
        });
    }, [mounted])

    useEffect(() => {
        setLoader(true);
        fetchData();
        return () => {
            mounted = false;
            setLoader(false);
        }
    }, [fetchData])

    useEffect(() => {
        if(!freshOrder) return;
        fetchData();
    }, [freshOrder])

    const onRefresh = async() => {
        return await Promise.all([
            fetchData(),
        ]);
    }

    const onSubmit = () => {
        setLoader(true);
        updateOrderItem(uuid, {note: note}).then(res => {
            setLoader(false);
            setSnack({open: true, text: res.messages});
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, theme: 'danger', text: err.messages.error});
        })
    }

    const handleDelete = (index, uuid) => {
        let result = {...data};
        setLoader(true);
        deleteOrderItemProduct(uuid).then(res => {
            result.items.splice(index, 1);
            setData(result);
            setFreshOrder(true);
            setLoader(false);
        }).catch(err => {
            setLoader(false);
            setSnack({open: true, theme: 'danger', text: err.messages.error});
        })
    }

    return (<>
        <div className="cart-page">
            <PullToRefresh
                className="pull-to-refresh"
                pullDownThreshold={75}
                maxPullDownDistance={120}
                resistance={2}
                onRefresh={() => onRefresh()}
                pullingContent=""
                refreshingContent={<div className="pdy-3 pdt-6"><CircularProgress color="inherit" size={30} /></div>}
            >
                <div className="cart-container">
                    {
                        data.items.length > 0 ? (<>
                            <Link to="/history" className="font-size-14 fw-bold text-main pdy-3 d-inline-block"><i className="fas fa-angle-left"></i> back</Link>
                            <div className="font-size-20 fw-bold text-main mgb-1 d-flex align-items-center">Order #{data.order_number} </div>
                            <div className="badge bg-main text-color mgb-3 font-size-12 rounded-pill text-capitalize">{data.status}</div>
                            <ul className="cart-list">
                                { data.items.map((item, index) => <CartItem orderStatus={data.status} item={item} index={index} key={index} freshOrder={freshOrder} setFreshOrder={setFreshOrder} handleDelete={handleDelete} />) }
                            </ul>
                            {
                                data.status === "pending" &&
                                <div className="pdy-4 d-flex fw-bold font-size-16 border-bottom" style={{margin: "0 -15px"}}>
                                    <ProductListLightbox freshOrder={freshOrder} fetchOrder={fetchData} />
                                </div>
                            }
                            <div className="pdy-3 d-flex flex-wrap fw-bold font-size-14">
                                <div className="col-12 col-sm-6 mgy-1">Est Dimension (Cu in)：<span className="text-main">{data.dimension}</span></div>
                                <div className="col-12 col-sm-6 mgy-1">Est Weight (lbs)：<span className="text-main">{data.weight}</span></div>
                                <div className="col-12 col-sm-6 mgy-1">Subtotal：<span className="text-main">${data.subtotal}</span></div>
                                { data.shipping_method === "delivery" && <div className="col-12 col-sm-6 mgy-1">Shipping Cost：<span className="text-main">${data.delivery_fee}</span></div> }
                            </div>
                            <div className="pd-3 fw-bold font-size-16 border-top" style={{margin: "0 -15px"}}>
                                <div className="">Discount ($)：<span className="text-main">${data.discount}</span></div>
                                <div className="mgt-3">Surcharge ($)：<span className="text-main">${data.surcharge}</span></div>
                                <div className="mgt-3">Shipping Method：<span className="text-main text-capitalize">{data.shipping_method}</span></div>
                                <div className="mgt-3">Grand Total：<span className="text-main">${data.total_price >= 0 ? data.total_price : "0"}</span></div>
                                <div className="mgt-6 d-flex">
                                    <span className="font-size-16 fw-bold">Note：</span>
                                    <textarea rows="3" onChange={(e) => setNote(e.target.value)} spellCheck={false} value={note} readOnly={data.status !== "pending"} />
                                </div>
                            </div>
                            {
                                data.status === "pending" && (
                                    <div className="submit-container">
                                        <button className="submit-btn d-inline-flex justify-content-center align-items-center" style={{maxWidth: "400px"}} type="button" onClick={onSubmit} disabled={loading}>Save</button>
                                    </div>
                                )
                            }
                        </>) : (
                            !loader && (
                                <div className="text-center" style={{margin: "100px 0"}}>
                                    <i className="fal fa-clipboard-list fa-5x"></i>
                                    <div className="font-size-18 text-main mgt-3">No order data</div>
                                    <div className="text-secondary mgt-3">This order has been deleted or no information or data can be found about the order.</div>
                                    <Link className=" pdy-2 pdx-4 mgt-6 text-white d-inline-block bg-main" to="/history">Back to history</Link>
                                </div>
                            )
                        )
                    }
                </div>
            </PullToRefresh>
        </div>
    </>)
}


const CartItem = (props) => {
    const { item, index, freshOrder, setFreshOrder, orderStatus, handleDelete } = props;
    const context = useContext(GlobalContext);
    const { setSnack, setLoader } = context;
    const [ loading, setLoading ] = useState(false);
    const [ quantity, setQuantity ] = useState("");
    const [ maxQuantity, setMaxQuantity ] = useState({maximum: null, limit: 0, stock: 0});
    const [ currentQuantity, setCurrentQuantity ] = useState("");
    const { handleKeyDown } = useKeyboard();

    const handleMaximum = useCallback(() => {
        // 情況一 前台有人手動退單
        // stock 30
        // safety_limit 20
        // limit 2
        // order_limit_quantity 1
        // (maximum: 1, stock: 0, limit: 1)

        // 情況二 後台reset過限購或沒買過限購且庫存高於安全值
        // stock 30
        // safety_limit 20
        // limit 2
        // order_limit_quantity 0
        // (maximum: 12, stock: 10, limit: 2)

        // 情況三 後台reset過限購或沒買過限購且庫存低於安全值
        // stock 10
        // safety_limit 20
        // limit 2
        // order_limit_quantity 0
        // (maximum: 2, stock: 0, limit: 2)

        // 情況四 買滿限購
        // stock 10
        // safety_limit 20
        // limit 2
        // order_limit_quantity 2
        // (maximum: 0, stock: 0, limit: 0)

        //原始 o_quantity 1
        //原始 o_stock 20
        //原始 o_safety_limit 0
        //原始 o_limit 0
        //原始 o_reserve_stock 0
        // (maximum: 21, stock: 21, limit: 0) maximum = o_quantity+o_stock-o_reserve_stock stock=o_quantity+o_stock-o_reserve_stock
        // (maximum: 21, stock: 21, limit: 0) maximum = o_quantity+o_stock-o_reserve_stock stock=o_quantity+o_stock-o_reserve_stock
        // (maximum: 21, stock: 21, limit: 0) maximum = o_quantity+o_stock-o_reserve_stock stock=o_quantity+o_stock-o_reserve_stock
        // (maximum: 21, stock: 21, limit: 0) maximum = o_quantity+o_stock-o_reserve_stock stock=o_quantity+o_stock-o_reserve_stock
        // (maximum: 21, stock: 21, limit: 0) maximum = o_quantity+o_stock-o_reserve_stock stock=o_quantity+o_stock-o_reserve_stock

        //原始 o_quantity 22
        //原始 o_stock 0
        //原始 o_safety_limit 0
        //原始 o_limit 0
        //原始 o_reserve_stock 1
        // (maximum: 21, stock: 21, limit: 0) maximum = o_quantity+o_stock-o_reserve_stock stock=o_quantity+o_stock-o_reserve_stock
        // (maximum: 21, stock: 21, limit: 0) maximum = o_quantity+o_stock-o_reserve_stock stock=o_quantity+o_stock-o_reserve_stock
        // (maximum: 21, stock: 21, limit: 0) maximum = o_quantity+o_stock-o_reserve_stock stock=o_quantity+o_stock-o_reserve_stock
        // (maximum: 21, stock: 21, limit: 0) maximum = o_quantity+o_stock-o_reserve_stock stock=o_quantity+o_stock-o_reserve_stock
        // (maximum: 21, stock: 21, limit: 0) maximum = o_quantity+o_stock-o_reserve_stock stock=o_quantity+o_stock-o_reserve_stock

        //原始 o_quantity 2
        //原始 o_stock 20
        //原始 o_safety_limit 10
        //原始 o_limit 5
        //原始 o_limit_quantity 0
        //原始 o_order_limit_quantity 0
        //原始 o_reserve_stock 1
        // (maximum: 16, stock: 11, limit: 5, quantity: 2, limit_quantity: 0) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        // (maximum: 16, stock: 11, limit: 5, quantity: 5, limit_quantity: 0) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        // (maximum: 16, stock: 11, limit: 5, quantity: 10, limit_quantity: 0) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        // (maximum: 16, stock: 11, limit: 5, quantity: 11, limit_quantity: 1) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        // (maximum: 16, stock: 11, limit: 5, quantity: 15, limit_quantity: 5) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit

        //原始 o_quantity 12 -> 11 -> 10
        //原始 o_stock 10 -> 10 -> 11
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_limit_quantity 0
        //原始 o_order_limit_quantity 0
        //原始 o_reserve_stock 1 -> 0
        // (maximum: 17, stock: 12, limit: 5, quantity: 2, limit_quantity: 0) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        // (maximum: 17, stock: 12, limit: 5, quantity: 5, limit_quantity: 0) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        // (maximum: 17, stock: 12, limit: 5, quantity: 10, limit_quantity: 0) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        // (maximum: 17, stock: 12, limit: 5, quantity: 11, limit_quantity: 1) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        // (maximum: 17, stock: 12, limit: 5, quantity: 15, limit_quantity: 5) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        
        //原始 o_quantity 2
        //原始 o_stock 10
        //原始 o_safety_limit 20
        //原始 o_limit 5
        //原始 o_limit_quantity 0
        //原始 o_order_limit_quantity 0
        //原始 o_reserve_stock 1
        // (maximum: 7, stock: 1, limit: 5, reserve stock: 1, quantity: 2, limit_quantity: 0) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        // (maximum: 7, stock: 1, limit: 5, reserve stock: 1, quantity: 3, limit_quantity: 0) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit
        // (maximum: 6, stock: 1, limit: 5, reserve stock: 0, quantity: 2, limit_quantity: 0) maximum = o_quantity-o_reserve_stock+o_stock-o_safety_limit+o_limit stock= o_quantity-o_reserve_stock+o_stock-o_safety_limit

        //原始 o_quantity 12
        //原始 o_limit 5
        //原始 o_limit_quantity 1
        //原始 o_order_limit_quantity 1
        //原始 o_reserve_stock 1
        // (maximum: 15, stock: 10, limit: 5, quantity: 12, limit_quantity: 1) maximum = o_quantity+o_limit-o_order_limit_quantity-o_reserve_stock stock = o_quantity-o_limit_quantity-o_reserve_stock
        // (maximum: 15, stock: 10, limit: 5, quantity: 13, limit_quantity: 2) maximum = o_quantity+o_limit-o_order_limit_quantity-o_reserve_stock stock = o_quantity-o_limit_quantity-o_reserve_stock
        // (maximum: 15, stock: 10, limit: 5, quantity: 16, limit_quantity: 5) maximum = o_quantity+o_limit-o_order_limit_quantity-o_reserve_stock stock = o_quantity-o_limit_quantity-o_reserve_stock

        //原始 o_quantity 1
        //原始 o_limit 5
        //原始 o_limit_quantity 1
        //原始 o_order_limit_quantity 2
        // (maximum: 4, stock: 0, limit: 4, quantity: 1, limit_quantity: 1) maximum = o_quantity+o_limit-o_order_limit_quantity-o_reserve_stock stock = o_quantity-o_limit_quantity-o_reserve_stock
        // (maximum: 4, stock: 0, limit: 4, quantity: 2, limit_quantity: 2) maximum = o_quantity+o_limit-o_order_limit_quantity-o_reserve_stock stock = o_quantity-o_limit_quantity-o_reserve_stock
        // (maximum: 4, stock: 0, limit: 4, quantity: 4, limit_quantity: 4) maximum = o_quantity+o_limit-o_order_limit_quantity-o_reserve_stock stock = o_quantity-o_limit_quantity-o_reserve_stock

        let maximum = item.quantity+item.stock-item.reserve_quantity; //沒限購情況 maximum = 庫存
        let stock = item.quantity+item.stock-item.reserve_quantity;
        if(item.limit > 0 && item.safety_limit > 0){
            //有限購情況下
            if(item.order_limit_quantity < item.limit){
                if(item.order_limit_quantity > 0) {
                    //有買過限購但沒有超出限購量
                    maximum = item.quantity+item.limit-item.order_limit_quantity-item.reserve_quantity;
                    stock = item.quantity-item.limit_quantity-item.reserve_stock;
                }else if(item.order_limit_quantity === 0) {
                    //沒有買過任何限購商品
                    if((item.quantity+item.stock) > item.safety_limit){
                        //且庫存 > 安全值
                        maximum = item.quantity+item.stock-item.safety_limit+item.limit-item.order_limit_quantity-item.reserve_quantity;
                        stock = item.quantity+item.stock-item.safety_limit > 0 ? item.quantity+item.stock-item.safety_limit : 0;
                    }else{
                        maximum = (item.quantity+item.stock) <= item.limit ? item.stock : item.limit;
                        stock = 0;
                    }
                }
                // if(item.limit_quantity < 1){
                //     stock = item.quantity+stock;
                // }
            }else{
                //買滿限購量
                maximum = 0;
                stock = 0;
                setQuantity("0");
            }
        }

        setMaxQuantity({...maxQuantity, maximum: maximum, stock: stock, limit: maximum - stock});
    }, [item])

    // useEffect(() => {
    //     handleMaximum();
    // }, [handleMaximum])

    useEffect(() => {
        if(!freshOrder) setLoading(false);
    } ,[freshOrder])

    useEffect(() => {
        setQuantity(item.quantity);
        setCurrentQuantity(item.quantity);
    }, [item])

    const handleUpdateCartItem = (value) => {
        if(!item.status || orderStatus !== "pending") return;
        setLoading(true);
        updateOrderItemProduct(item.uuid, {quantity: value}).then(res => {
            setFreshOrder(true);
        }).catch(err => {
            setFreshOrder(true);
            setLoading(false);
            setLoader(false);
            setSnack({open: true, text: err.messages.error});
        })
    }

    const handleChange = (value) => {
        if(!item.status || orderStatus !== "pending") return;
        if(parseInt(value) > maxQuantity.maximum){
            setQuantity(maxQuantity.maximum);
        }else{
            setQuantity(value);
        }
    }
    
    return (
        <li className="cart-item">
            <div className="d-flex align-items-stretch flex-fill">
                {
                    (item.image !== "" && item.image) ? (
                        <div className="cart-image" style={{backgroundImage: `url(${item.image})`}}></div>
                    ) : (
                        <div className="cart-image"></div>
                    )
                }
                <div className="cart-info">
                    <div className="category">{item.category.name}</div>
                    <div className="name">{item.name}</div>
                    <div className="name">{item.zh_name}</div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="price">Price ($)：{item.price}</div>
                        { 
                            orderStatus === "pending" &&
                            <ConfirmButton
                                className="remove-btn"
                                onSuccess={() => handleDelete(index, item.uuid)}
                                confirmText="Are you sure you want to delete item?"
                                confirm={{show: true, text: 'Yes'}}
                                cancel={{show: true, text: 'Cancel'}}
                            >
                                <i className="far fa-trash-alt mgr-1"></i>remove item
                            </ConfirmButton>
                        }
                    </div>
                </div>
            </div>
            <div className="quantity-container d-flex justify-content-end align-items-center">
                {/* {
                    ((item.safety_limit > 0 && parseInt(quantity) >= maxQuantity-item.limit) || item.safety_limit <= 0 && item.limit > 0) &&
                    <div className="mgr-2 font-size-12 text-main">Maximum: {maxQuantity}</div>
                } */}
                {/* {
                    item.limit > 0 &&
                    <div className="mgr-2 font-size-12 text-main">Maximum: {maxQuantity}</div>
                } */}
                {
                    orderStatus === "pending" ? (<>
                        {/* <div className="quantity-box">
                            <button
                                type="button"
                                className="quantity-btn"
                                onClick={() => {
                                    if(quantity > 1){
                                        setQuantity(quantity - 1);
                                        handleUpdateCartItem(quantity - 1);
                                    } 
                                }}
                                disabled={parseInt(quantity) <= 1 || loading || !item.status || orderStatus !== "pending"}
                                >-
                            </button>
                            <input
                                tabIndex="1"
                                type="number"
                                className="quantity-input"
                                step="1"
                                pattern="[0-9]*"
                                inputMode="numeric"
                                autoComplete="off"
                                spellCheck="false"
                                value={quantity}
                                onChange={(e) => handleChange(e.target.value, maxQuantity)}
                                onKeyDown={(e) => handleKeyDown(e, { number: true })}
                                onWheel={(e) => e.target.blur()}
                                onPaste={(e) => e.preventDefault()}
                                onBlur={(e) => {
                                    if(e.target.value === "" || parseInt(e.target.value) < 1){
                                        setQuantity(1);
                                        handleUpdateCartItem(1);
                                    }else{
                                        setQuantity(parseFloat(e.target.value));
                                        if(currentQuantity !== parseInt(e.target.value)){
                                            handleUpdateCartItem(quantity);
                                        }
                                    }
                                }}
                                readOnly={loading || !item.status || orderStatus !== "pending"}
                            />
                            <button
                                type="button"
                                className="quantity-btn"
                                onClick={() => {
                                    setQuantity(quantity + 1);
                                    handleUpdateCartItem(quantity + 1);
                                }}
                                disabled={parseInt(quantity) >= maxQuantity.maximum || loading || !item.status || orderStatus !== "pending"}
                                >+
                            </button>
                        </div> */}
                    </>) : (
                        <div className="text-main fw-bold">Qty：{quantity}</div>
                    )
                }
            </div>
            {
                orderStatus === "pending" &&
                <div className="mgt-3 d-flex justify-content-between align-items-center">
                    <div className="text-main fw-bold">Item Total：${quantity !== "" ? Math.round(item.price*parseInt(quantity)*10000)/10000 : '0'}</div>
                    {/* <div className="text-main fw-bold">Stock：{item.stock ? item.stock : "0"}</div> */}
                    <div className="text-main">
                        {/* <div className="fw-bold">Maximum：{maxQuantity.maximum}</div> */}
                        <div className="fw-bold">Qty：{quantity}</div>
                        {/* <div className="font-size-12">(stock: {maxQuantity.stock}, limit: {maxQuantity.limit})</div> */}
                    </div>
                </div>
            }
        </li>
    )
}