import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import SearchIcon from '@mui/icons-material/Search';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const SearchBar = () => {
    const history = useHistory();
    const [ toggle, setToggle ] = useState(false);
    const [ keyword, setKeyword ] = useState("");

    return (<>
        <ClickAwayListener onClickAway={() => setToggle(false)}>
            <div>
                <SearchIcon sx={{cursor: "pointer", display: toggle ? 'none' : 'block'}} onClick={() => setToggle(true)} />
                {
                    toggle &&
                    <div className={`search-bar ${toggle ? 'active' : ''}`}>
                        <SearchIcon />
                        <input
                            type="text"
                            placeholder="search for products"
                            autoComplete="off"
                            spellCheck="false"
                            onChange={(e) => setKeyword(e.target.value)}
                            value={keyword}
                            autoFocus
                            onKeyPress={(e) => {
                                if(keyword !== "" && e.key === "Enter"){
                                    history.push(`/search?keyword=${e.target.value}`);
                                    setToggle(false);
                                    e.target.blur();
                                }
                            }}
                        />
                        { keyword !== "" &&  <div className="close-search" onClick={() => setKeyword("")}><i className="far fa-times-circle "></i></div>}
                    </div>
                }
            </div>
        </ClickAwayListener>
    </>)
}

export default SearchBar;
