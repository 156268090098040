import React from 'react';

const FormGroup = (props) => {
    const { label, labelInfo, className, required, children, direction, sx } = props;

    return (<>
        <div className={`form-group${className ? ` ${className}` : ''}${direction ? ` dir-${direction}` : ''}`} style={sx}>
            {
                label &&
                <div className={`label-group${labelInfo ? ` mgb-0` : ''}`}>
                    <label>{ required && <span className="text-danger">* </span>}{ label }</label>
                    { labelInfo && <div className="font-size-12 mgb-1" style={{color: "#707a8a"}}>{ labelInfo }</div> }
                </div>
            }
            { children }
        </div>
    </>)
}

const InputGroup = (props) => {
    const { children, className, sx, theme, error} = props;

    return (<>
        <div className={`custom-input-group${className ? ` ${className}` : ''}${theme ? ` theme-${theme}` : ' theme-outline'}${error ? ` error` : ''}`} style={sx}>
            { children }
        </div>
        { error && <div className="font-size-12 text-danger pdx-1 pdt-1">{error.message}</div> }
    </>)
}

const TextGroup = ({ children }) => {
    return(
        <div className="text-group">
            { children }
        </div>
    )
}

export default FormGroup;

export { InputGroup, TextGroup };