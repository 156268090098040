import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import LightBox from 'components/LightBox';
import OrderProductList from 'components/pages/OrderProductList';
import { getOrderProductsList } from 'services/authService';

export default function ProductListLightbox({fetchOrder, freshOrder}) {
    let mounted = true;
    const { uuid } = useParams();
    const [ toggle, setToggle ] = useState(false);
    const [ data, setData ] = useState([]);
    const [ search, setSearch ] = useState("");

    const fetchProductList = useCallback(async() => {
        let result = await getOrderProductsList({order_uuid: uuid});
        result.map((item, index) => result[index] = {...item, quantity: "1"});
        if(mounted){
            setData(result);
        }
    }, [mounted])

    useEffect(() => {
        freshOrder && fetchProductList();
    }, [freshOrder])

    useEffect(() => {
        fetchProductList();
        return () => mounted = false;
    }, [fetchProductList])

    const handleSearch = () => {
        if(search === "") return data;
        return data.filter(e => e.sku.toLowerCase().indexOf(search.toLowerCase()) !== -1 || e.name.toLowerCase().indexOf(search.toLowerCase()) !== -1 || e.zh_name.toLowerCase().indexOf(search.toLowerCase()) !== -1);
    }

    const handleChangeData = (item, value) => {
        let index = data.findIndex(e => e.uuid === item.uuid);
        let result = Array.from(data);
        result[index].quantity = value.toString();
        setData(result);
    }

    return (<>
        <button className="pdy-1 pdx-3 bg-main text-white rounded-pill border-0 m-auto" style={{boxShadow: "1px 2px 3px rgba(0,0,0,0.2)"}} type="button" onClick={() => setToggle(true)}>＋ 新增商品</button>
        <LightBox
            open={toggle}
            callback={() => {
                setToggle(false);
                let result = Array.from(data);
                result.map((item, index) => result[index] = {...item, quantity: "1"});
                setTimeout(() => {
                    setData(result);
                }, 300)
            }}
            className="product-list-light-box"
            scroll="paper"
            cross
        >
            <div className="light-box-header pd-3 bg-white">
                <div className="text-main fw-bold font-size-16">新增商品</div>
                <input
                    className="form-control mgt-3 font-size-14"
                    type="text"
                    placeholder="搜尋SKU / 商品名稱"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <div className="light-box-body pd-3 bg-white">
                <OrderProductList loading={false} data={handleSearch()} setData={(item, value) => handleChangeData(item, value)} fetchOrder={fetchOrder} fetchProductList={fetchProductList} />
            </div>
        </LightBox>
    </>)
}
