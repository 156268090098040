import React, { useState } from 'react'
import { Link } from 'react-router-dom';
// import Collapse from 'react-bootstrap/Collapse';
import Collapse from '@mui/material/Collapse';

const UnitBox = ({item}) => {
    const [ toggle, setToggle ] = useState(false);

    const handleClick = () => {
        window.innerWidth < 992 && setToggle(!toggle);
        return;
    }

    return (<>
        <div
            className={`nav-label${toggle ? ' active': ''}`}
            onClick={() => handleClick()}
        >
            {item.label}
        </div>
        {
            item.children && (
                <div className="unit-box">
                    <Collapse in={toggle}>
                        <ul className="unit-list">
                            {
                                item.children.map((unitItem, index) => 
                                    <li className="unit-item" key={index}>
                                        <Link className="unit-link" to={unitItem.link}>
                                            {unitItem.label}
                                        </Link>
                                    </li>
                                )
                            }
                        </ul>
                    </Collapse>
                </div>
            )
        }
    </>)
}


export default UnitBox;