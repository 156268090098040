import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { GlobalContext } from 'store/context/GlobalContext';

const PublicRoute = ({component: Component, ...rest}) => {
    const isAuthenticated = useSelector((state) => state.Auth.isAuthenticated);
    const context = useContext(GlobalContext);
    const { hasHeader, hasFooter, mainTitle, subTitle, background } = rest;
    const initialPageProps = { hasHeader, hasFooter, mainTitle, subTitle, background };
    
    useEffect(() => {
        context.setPageState(initialPageProps);
        window.scrollTo(0, 0);
    }, []);
    
    return (
        <Route {...rest} render={props => (
            !isAuthenticated ? (
                <Component {...props} />
            ) : (
                <Redirect to={{
                    pathname: '/home',
                    state: { from: props.location },
                }}/>
            )
        )} />
    )
}

export default PublicRoute;