import Http from "utils/Http";
import * as action from "store/actions";

let API_URL = process.env.REACT_APP_ENVIRONMENT === 'development' ? process.env.REACT_APP_TEST_API_URL : process.env.REACT_APP_API_URL;

export function login(credentials) {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/auth/login", credentials)
				.then(res => {
					dispatch(action.authLogin(res.data));
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

export function logout() {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/logout")
				.then(res => {
					dispatch(action.authLogout());
					dispatch(action.clearCart());
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

export function refreshToken() {
	let credentials = {
		grant_type: "refresh_token",
		refresh_token: localStorage.getItem('refresh_token')
	}
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.post(API_URL+"/auth/refreshToken", credentials)
				.then(res => {
					dispatch(action.authRefreshToken(res.data));
					return resolve(res.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
		});
}

//註冊--------------------------------------------------------------------------------------------
export function registerPhoneVerify(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/register/phone", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

export function registerSubmit(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/register", credentials)
			.then(response => {
				return resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}


//忘記密碼取得手機驗證碼---------------------------------------------------------------------------
export function forgotPhoneVerify(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/forgot/phone", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

//取得信箱驗證碼---------------------------------------------------------------------------
export function forgotEmailVerify(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/forgot/email", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

//送出驗證碼-----------------------------------------------------------------------------------
export function forgotVerify(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/forgot/verify", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

//忘記密碼修改---------------------------------------------------------------------------
export function forgotSubmit(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL + "/auth/forgot", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
			console.clear();
	});
}

export function fetchUser() {
	return dispatch =>
		new Promise((resolve, reject) => {
			Http.get(API_URL+"/me")
				.then(res => {
					dispatch(action.authUser(res.data));
					return resolve();
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err);
					}
				});
		});
}

//首頁----------------------------------------------------------------------------------
export function getBannerList() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/banner")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getNewsCarousel() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/home/news/carousel")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//----------------------------------------------------------------------------------------

//Brands 品牌----------------------------------------------------------------------------------
export function getBrandsList() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/brands")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getAllCategory() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/category")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getBrandsCategory(slug) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/brands/"+slug+"/category")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getAllProducts(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/products", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getBrandsProducts(slug, credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/brands/"+slug+"/products", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getOrderProductsList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/products/all", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function addOrderItem(uuid, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/order/"+uuid+"/addItem", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}
//----------------------------------------------------------------------------------------

//Cart 購物車----------------------------------------------------------------------------------
export function getCart() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/cart")
			.then(response => {
				return resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function addToCart(uuid, credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/cart/"+uuid+"/add", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}

export function updateCartItem(uuid, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/cart/"+uuid+"/update", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteCartItem(uuid) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/cart/"+uuid+"/remove")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}
//----------------------------------------------------------------------------------------

//Order 訂單----------------------------------------------------------------------------------
export function getOrder(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/order", { params: credentials })
			.then(response => {
				return resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function orderSubmit(credentials) {
	return new Promise((resolve, reject) => {
		Http.post(API_URL+"/order", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getOrderItem(uuid) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/order/"+uuid)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}

export function updateOrderItem(uuid, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/order/"+uuid+"/update", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}

export function updateOrderItemProduct(uuid, credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/order/item/"+uuid+"/update", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteOrderItem(uuid) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/order/"+uuid+"/remove")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}

export function deleteOrderItemProduct(uuid) {
	return new Promise((resolve, reject) => {
		Http.delete(API_URL+"/order/item/"+uuid+"/remove")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422 || statusCode === 500) {
					reject(err.response.data);
				}
			});
	});
}

//----------------------------------------------------------------------------------------

//Profile----------------------------------------------------------------------------------
export function updateProfile(credentials) {
	const config = {
		headers: {'Content-Type': 'application/x-www-form-urlencoded'}
	};

	return new Promise((resolve, reject) => {
			Http.post(API_URL+"/user", credentials, config)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}
//-----------------------------------------------------------------------------------------

//Notification----------------------------------------------------------------------------------

export function getAllNotification() {
	return new Promise((resolve, reject) => {
			Http.get(API_URL+"/user/notification")
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}

export function getNotificationUnreadCount() {
	return new Promise((resolve, reject) => {
			Http.get(API_URL+"/user/notification/count/unread")
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}

export function getNotification(id) {
	return new Promise((resolve, reject) => {
			Http.get(API_URL+"/user/notification/"+id)
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}

export function readAllNotification() {
	return new Promise((resolve, reject) => {
			Http.put(API_URL+"/user/notification/read")
				.then(response => {
					resolve(response.data);
				})
				.catch(err => {
					const statusCode = err.response.status;
					if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
						reject(err.response.data);
					}
				});
	});
}

//-----------------------------------------------------------------------------------------

//News----------------------------------------------------------------------------------

export function getNewsList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/news", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getNewsItem(slug) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/news/"+slug)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-----------------------------------------------------------------------------------------

//Events----------------------------------------------------------------------------------

export function getEventsList(credentials) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/events", { params: credentials })
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}

export function getEventItem(slug) {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/events/"+slug)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-----------------------------------------------------------------------------------------

//Language----------------------------------------------------------------------------------
export function updateLanguage(credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/user/language", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-----------------------------------------------------------------------------------------

//關於我們、About----------------------------------------------------------------------------------
export function getAbout() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/about")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//------------------------------------------------------------------------------------------------

//新手指南、Guide----------------------------------------------------------------------------------
export function getGuide() {
	return new Promise((resolve, reject) => {
		Http.get(API_URL+"/guide")
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//------------------------------------------------------------------------------------------------

//修改密碼、Password----------------------------------------------------------------------------------
export function updatePassword(credentials) {
	return new Promise((resolve, reject) => {
		Http.put(API_URL+"/user/password", credentials)
			.then(response => {
				resolve(response.data);
			})
			.catch(err => {
				const statusCode = err.response.status;
				if (statusCode === 400 || statusCode === 401 || statusCode === 422) {
					reject(err.response.data);
				}
			});
	});
}
//-----------------------------------------------------------------------------------------