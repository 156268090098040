import {
    UPDATE_PRODUCT_VIEW_MODE,
} from 'store/action-types';

const viewMode = "grid";

const initialState = {
    viewMode,
};

const ProductViewMode = (state = initialState, {type, payload = null}) => {
    switch(type){
        case UPDATE_PRODUCT_VIEW_MODE:
            return updateProductViewMode(state, payload);
        default:
            return state;
    }
};

const updateProductViewMode = (state, payload) => {
    return {
        ...state,
        viewMode: payload,
    }
}


export default ProductViewMode;