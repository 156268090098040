import React, { useState } from 'react';
import { Link, useLocation, useHistory } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import Skeleton from '@mui/material/Skeleton';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

const CategorySidebar = ({children}) => {
    return (
        <aside className="category-sidebar">
            {children}
        </aside>
    )
}

const CategoryBrandLink = (props) => {
    const location = useLocation();
    const { to, label, loading } = props;

    return (
        loading ? (
            <div className="pdy-2 pdx-3">
                <Skeleton variant="rectangular" height="25px" width="100%" style={{borderRadius: "4px"}} />
            </div>    
        ) : (
            <Link to={to} className={`category-brands-link${(location.pathname+location.search === to) ? ' active' : ''}`}>
                <i className="fas fa-home mgr-2"></i>{label}
            </Link>
        )
    )
}

const CategoryList = (props) => {
    const { data } = props;
    const location = useLocation();
    const category = new URLSearchParams(location.search).get('category');

    const handleTree = (childrens) => {
        return childrens.map((item, index) => <CategoryItem item={item} key={index} />)
    }

    const CategoryItem = (props) => {
        const { item } = props;
        const [ toggle, setToggle ] = useState(true);

        return (
            <li className="category-item">
                {
                    !item.children ? (
                        <Link className={`category-link${item.slug === category ? ` text-main` : ''}`} to={item.link}>
                            {item.name}
                        </Link>
                    ) : (<>
                        <div className={`category-label${toggle ? ' active' : ''}`} onClick={() => setToggle(!toggle)}>{item.name}</div>
                        <Collapse in={toggle}>
                            <ul className="category-list">
                                { handleTree(item.children) }
                            </ul>
                        </Collapse>
                    </>)
                }
            </li>
        )
    }

    return (
        <ul className="category-list">
            { handleTree(data) }
        </ul>
    )

}

const SelectCategoryList = (props) => {
    const history = useHistory();
    const location = useLocation();
    const { data, brandLink, loading } = props;
    
    const handleTree = (array) => {
        return array.map((item, index) =>
            item.children ? (
                <optgroup label={item.name} key={index}>
                    {handleTree(item.children)}
                </optgroup>
            ) : (
                <option value={item.link} key={index}>{item.name}</option>
            )
        )
    }

    return (
        <FormControl sx={{marginBottom: "20px", marginLeft: "15px"}}>
            {
                loading ? (<>
                    <label className="font-size-12 mgb-0" style={{color: "rgba(0,0,0,0.6)", fontFamily: "Roboto,Helvetica,Arial,sans-serif"}}>Category</label>
                    <Skeleton variant="rectangular" height="20px" width="100px" style={{borderRadius: "4px", marginTop: "7px"}} />
                </>) : (<>
                    <InputLabel variant="standard" htmlFor="category_select">
                    Category
                    </InputLabel>
                    <NativeSelect
                        onChange={(e) => history.push(e.target.value ? e.target.value : '')}
                        value={location.pathname+location.search}
                        inputProps={{
                            name: 'category',
                            id: 'category_select',
                        }}
                        sx={{fontSize: "12px"}}
                    >
                        <option value={brandLink.link}>{brandLink.label}</option>
                        { handleTree(data) }
                    </NativeSelect>
                </>)
            }
        </FormControl>
    )  
}

export default CategorySidebar;

export { CategoryBrandLink, CategoryList, SelectCategoryList };