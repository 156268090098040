import { useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { GlobalContext } from 'store/context/GlobalContext';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Badge from '@mui/material/Badge';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import * as action from "store/actions";
import { getCart, deleteCartItem } from 'services/authService';

const CartPopper = ({sx}) => {
    const history = useHistory();
    const targetRef = useRef(null);
    const cartData = useSelector((state) => state.Cart);
    const context = useContext(GlobalContext);
    const { cartPopperToggle, setCartPopperToggle } = context;

    return (<>
        <ClickAwayListener onClickAway={() => setCartPopperToggle(false)}>
            <div>
                <Badge
                    badgeContent={cartData.items.length}
                    color="error"
                    sx={sx}
                    onClick={() => setCartPopperToggle(!cartPopperToggle)}
                    ref={targetRef}
                >
                    <ShoppingCartIcon />
                </Badge>
                <Popper
                    className="cart-popper"
                    id="cart-popper"
                    open={cartPopperToggle}
                    anchorEl={targetRef.current}
                    transition
                    disablePortal
                >
                    {({ TransitionProps }) => (
                        <Grow
                            style={{ transformOrigin: '50% 0 0'}}
                            timeout={350}
                            {...TransitionProps}
                        >
                            <div className="cart-container">
                                <div className="popper-body">
                                    {
                                        cartData.items.length > 0 ? (<>
                                            <ul className="cart-list">
                                                { cartData.items.map((item, index) => <CartItem item={item} index={index} key={index} />)}
                                            </ul>
                                            <div className="d-flex justify-content-between align-items-center font-size-14 font-weight-bold mgt-3 pdx-3">
                                                <div className="text-secondary">{cartData.count} items</div>
                                                <div>Subtotal：${cartData.subTotal}</div>
                                            </div>
                                            <div className="pd-3">
                                                <button className="cart-checkout-btn" onClick={() => {
                                                    setCartPopperToggle(false);
                                                    history.push('/cart');
                                                }}>Checkout</button>
                                            </div>
                                        </>) : (
                                            <div className="text-center pd-3">
                                                <i className="fal fa-shopping-bag fa-3x"></i>
                                                <div className="font-size-16 text-main mgt-3">Your Cart is empty</div>
                                                <div className="text-secondary font-size-14 mgt-3">Looks like you haven't added anything to your cart yet.</div>
                                                <button className="cart-checkout-btn mgt-3" onClick={() => {
                                                    setCartPopperToggle(false);
                                                    history.push('/inventory');
                                                }}>Shop Now</button>
                                            </div>
                                        )
                                    }
                                </div>
                            </div>
                        </Grow>
                    )}
                </Popper>
            </div>
        </ClickAwayListener>
    </>)
}

const CartItem = (props) => {
    const { item, index } = props;
    const dispatch = useDispatch();
    const cartData = useSelector((state) => state.Cart);

    const handleDelete = () => {
        let newCartData = {...cartData};
        newCartData.items.splice(index, 1);
        newCartData.subTotal = Math.round((newCartData.subTotal - item.price*item.quantity)*10000)/10000;
        newCartData.count -= 1;
        dispatch(action.updateCart(newCartData));

        deleteCartItem(item.uuid);
    }


    return (
        <li className="cart-item">
            {
                (item.image !== "" && item.image) ? (
                    <div className="cart-image" style={{backgroundImage: `url(${item.image})`}}></div>
                ) : (
                    <div className="cart-image"></div>
                )
            }
            <div className="cart-info">
                <div className="category">{item.category.name}</div>
                <div className="name">{item.name}</div>
                <div className="name">{item.zh_name}</div>
                <div className="d-flex justify-content-between align-items-center mgt-1">
                    <div className="quantity">{item.quantity} x ${item.price}</div>
                    <i className="far fa-trash-alt" style={{cursor: "pointer"}} onClick={() => handleDelete()}></i>
                </div>
                {
                    (((item.stock <= item.safety_limit) && item.quantity > 0) || ((item.stock > item.safety_limit) && item.quantity - (item.stock-item.safety_limit) > 0)) &&
                    <div className="badge fw-normal text-white mgt-1 d-inline" style={{backgroundColor: "#f37bb3"}}>has {item.stock <= item.safety_limit ? item.quantity : item.quantity - (item.stock-item.safety_limit)} limited item</div>
                }
            </div>
        </li>
    )
}

export default CartPopper;