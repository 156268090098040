import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ViewListIcon from '@mui/icons-material/ViewList';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { useSelector, useDispatch } from 'react-redux';
import * as action from "store/actions";
import { getAllProducts } from 'services/authService';
import ProductList from 'components/pages/ProductList';
import Pagination from '@mui/material/Pagination';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';

export default function Search() {
    let mounted = true;
    const dispatch = useDispatch();
    const location = useLocation();
    const keyword = new URLSearchParams(location.search).get('keyword');
    const viewMode = useSelector((state) => state.ProductViewMode.viewMode);
    const [ productList, setProductList ] = useState([]);
    const [ productParams, setProductParams ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ paginationParams, setPaginationParams ] = useState({count: 0, activePage: 0, itemsCountPerPage: 0});

    useEffect(() => {
        const fetchProducts = async(params) => {
            setLoading(true);
            let result = await getAllProducts(params);
            if(mounted){
                setProductList(result.data);
                setPaginationParams({count: result.total, activePage: result.current_page, itemsCountPerPage: result.per_page});
                setLoading(false);
            }
        }

        fetchProducts({...productParams, keyword: keyword});

        return () => mounted = false;
    }, [productParams, keyword])

    const handlePageChange = (event,idx) => {
        setPaginationParams({...paginationParams, activePage: idx});
        handleProductParamsChange({page: idx});
    }

    const handleProductParamsChange = (params) => {
        let result = {...productParams, ...params};
        if(!params['page']) delete result["page"];
        Object.keys(result).map(keyName => result[keyName] === "" && delete result[keyName]);
        setProductParams(result);
    }

    const handleViewMode = (event, newValue) => {
        if (newValue !== null) {
            dispatch(action.updateProductViewMode(newValue));
        }
    };

    return (
        <div className="brands-page">
            <div className="brands-wrapper">
                <div className="brands-container">
                    <div className="brands-content">
                        <div className="font-size-20 fw-bold text-main mgb-6">Search：{keyword}</div>
                        <div className="d-flex justify-content-end align-items-center flex-wrap">
                            <FormControl sx={{marginBottom: "20px", marginLeft: "15px"}}>
                                <InputLabel variant="standard" htmlFor="stock_select">Stock</InputLabel>
                                <NativeSelect
                                    onChange={(e) => handleProductParamsChange({stock: e.target.value})}
                                    value={productParams && productParams.stock ? productParams.stock : "default"}
                                    inputProps={{
                                        id: 'stock_select',
                                    }}
                                    sx={{fontSize: "12px"}}
                                >
                                    <option value="default">All products</option>
                                    <option value="still_in">Still in stock</option>
                                    <option value="sold_out">Sold out</option>
                                </NativeSelect>
                            </FormControl>
                            <FormControl sx={{marginBottom: "20px", marginLeft: "15px"}}>
                                <InputLabel variant="standard" htmlFor="sort_by_select">Sort by</InputLabel>
                                <NativeSelect
                                    onChange={(e) => handleProductParamsChange({sort_by: e.target.options[e.target.selectedIndex].getAttribute('data-sort'), order_by: e.target.options[e.target.selectedIndex].getAttribute('data-order')})}
                                    value={productParams && (productParams.sort_by && productParams.order_by) ? productParams.sort_by+"_"+productParams.order_by : "created_at_desc"}
                                    inputProps={{
                                        id: 'sort_by_select',
                                    }}
                                    sx={{fontSize: "12px"}}
                                >
                                    <option value="created_at_desc" data-sort="created_at" data-order="desc">Newest to Oldest</option>
                                    <option value="created_at_asc" data-sort="created_at" data-order="asc">Oldest to Newest</option>
                                    <option value="price_desc" data-sort="price" data-order="desc">Price：High to Low</option>
                                    <option value="price_asc" data-sort="price" data-order="asc">Price：Low to High</option>
                                </NativeSelect>
                            </FormControl>
                            <ToggleButtonGroup
                                value={viewMode}
                                exclusive
                                onChange={handleViewMode}
                                size="small"
                                sx={{margin: "0 0 20px 15px"}}
                            >
                                <ToggleButton value="grid">
                                    <ViewModuleIcon />
                                </ToggleButton>
                                <ToggleButton value="list">
                                    <ViewListIcon />
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </div>
                        <ProductList loading={loading} data={productList} />
                        {
                            productList.length > 0 && paginationParams.count !== 0 && paginationParams.count !== undefined && paginationParams.itemsCountPerPage !== 0 &&
                            <div className="mgt-6 pdx-3 text-center">
                                <Pagination count={Math.ceil(paginationParams.count/paginationParams.itemsCountPerPage)} page={paginationParams.activePage} defaultPage={1} onChange={handlePageChange}/>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
}