import React, { useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { useDispatch } from 'react-redux';
import Div100vh from 'react-div-100vh';
import FormGroup, { InputGroup } from 'components/Form/FormGroup';
import PasswordShowButton from 'components/Form/PasswordShowButton';
import { login } from 'services/authService';
import { GlobalContext } from 'store/context/GlobalContext';
import LogoImage from 'components/LogoImage';
import Spinner from 'react-bootstrap/Spinner';

export default function Page() {
    const dispatch = useDispatch();
    const context = useContext(GlobalContext);
    const { setSnack } = context;
    const { handleSubmit, formState: { isDirty, isValid, errors }, register } = useForm({ mode: 'onChange', shouldUnregister: true });
    const [ passwordShow, setPasswordShow ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const onSubmit = (data) => {
        if(data.password.length < 6){
            setSnack({open: true, text: "Password must be at least 6 characters"});
            return;
        }
        setLoading(true);
        dispatch(login(data)).then(res => {
        }).catch(err => {
            setSnack({open: true, theme: 'danger', text: err.messages.error});
            setLoading(false);
        })
    }

    return (<>
        <Div100vh>
            <div className="login-page">
                <div className="logo-container">
                    <LogoImage theme="white" sx={{width: "250px"}} />
                    <div className="font-size-16 text-white mgt-4 mgb-6">
                        <div>店家訂貨系統</div>
                        <div>Ordering system</div>
                    </div>
                    <div className="login-box">
                        <h5 className="fw-bold font-size-20">Login</h5>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <FormGroup label="Username">
                                <InputGroup error={errors.username}>
                                    <i className="fal fa-user-circle font-size-22 mgl-2 text-main"></i>
                                    <input
                                        tabIndex="1"
                                        name="username"
                                        type="text"
                                        autoComplete="off"
                                        spellCheck="false"
                                        {...register("username", {
                                            required: {
                                                value: true,
                                                message: '* Please enter a username',
                                            },
                                        })}
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup label="Password">
                                <InputGroup error={errors.password}>
                                    <i className="fal fa-key font-size-22 mgl-2 text-main"></i>
                                    <input
                                        tabIndex="1"
                                        name="password"
                                        type={passwordShow ? "text" : "password"}
                                        autoComplete="new-password"
                                        spellCheck="false"
                                        {...register("password", {
                                            required: {
                                                value: true,
                                                message: '* Please enter a password',
                                            },
                                            minLength: {
                                                value: 6,
                                                message: 'Password must be at least 6 characters',
                                            }
                                        })}
                                    />
                                    <PasswordShowButton passwordShow={passwordShow} setPasswordShow={setPasswordShow} />
                                </InputGroup>
                            </FormGroup>
                            <div className="text-right mgt-6">
                                <button
                                    type="submit"
                                    className="login-btn d-inline-flex align-items-center"
                                    disabled={!isDirty || !isValid || loading}
                                >
                                    <span>Login</span>
                                    { loading ? <Spinner className="mgl-2" animation="border" size="sm" /> : <i className="fas fa-long-arrow-right mgl-2"></i> }
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </Div100vh>
    </>)
}