import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import Skeleton from '@mui/material/Skeleton';
import useSearchArray from 'components/useSearchArray';

const Breadcrumb = (props) => {
    const { data } = props;
    const location = useLocation();
    const { brand } = useParams();
    const category = new URLSearchParams(location.search).get('category');
    const [ breadcrumbList, setBreadcrumbList ] = useState({});
    const { searchArrayTree } = useSearchArray();

    useEffect(() => {
        let clonedData = JSON.parse(JSON.stringify(data));

        if(data.length < 1) setBreadcrumbList({});

        if(category && data.length > 0){
            let lists = searchArrayTree(clonedData, "slug", category);
            setBreadcrumbList(lists);
            return;
        }

        if(data.length > 0) {
            let lists = searchArrayTree(clonedData, "slug", data[0].slug);
            setBreadcrumbList(lists);
            return;
        }

        return () => {
            setBreadcrumbList({});
        }
    }, [category, data, brand])

    const handleBreadcrumb = (obj) => {
        return (<>
            <li className="breadcrumb-item">{ (obj['icon']) && <i className={obj['icon']}></i>} { obj['name'] }</li>
             { obj['children'] && (<>{handleBreadcrumb(obj['children']) } </>) }
        </>)
    }

    return (
        <div className="breadcrumb-container">
            {
                Object.keys(breadcrumbList).length > 0 ? (
                    <ul className="breadcrumb-list">
                        { handleBreadcrumb(breadcrumbList) }
                    </ul>
                ) : (
                    <Skeleton variant="rectangular" height="20px" width="150px" style={{borderRadius: "4px"}} />
                )
            }
        </div>
    )
}

export default Breadcrumb;