import React, { useState, useEffect } from 'react';
import ConfirmBox from './ConfirmBox';

const ConfirmButton = (props) => {
    const { children, className, style, onValidate, onSubmit, onSuccess, confirmText, confirm, cancel, scroll, disabled } = props;
    const [ confirmBoxToggle, setConfirmBoxToggle ] = useState(false);

    const handleClick = () => {
        if(onValidate) {
            let validate = onValidate();
            if(validate) setConfirmBoxToggle(true)
        } else {
            setConfirmBoxToggle(true)
        }
    }

    const handleConfirm = () => {
        onSubmit && onSubmit();
        onSuccess&& onSuccess();
    }

    return (<>
        <button
            type="button"
            className={className ? className : 'submit-btn'}
            style={style}
            onClick={() => handleClick() }
            disabled={disabled}
        >
            { children ? children : '送出' }
        </button>

        <ConfirmBox
            toggle={confirmBoxToggle}
            setToggle={setConfirmBoxToggle}
            confirmCallback={handleConfirm}
            confirmText={confirmText ? confirmText : '確定要送出嗎'}
            confirm={confirm ? confirm : {show: true, text: '確定'}}
            cancel={cancel ? cancel : {show: true}}
            scroll={scroll}
        />
    </>)
}

export default ConfirmButton;